import React from 'react';
import InsuranceEditForm from '../../components/InsuranceEditForm';


const AddAdminUser = () => {
    return (
        <div>
            <div className="bg-white shadow-lg py-4 px-4 rounded-4">
            <div className="d-flex align-items-center mb-3">
                    <div className="col-auto d-block">
                        <h2 className="table-title text-dark fw-semibold fs-3">Edit Insurance</h2>
                    </div>
                    </div>        
                   <InsuranceEditForm />
                
            </div>
            
        </div>
    );
};

export default AddAdminUser;