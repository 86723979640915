import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';

const AdminProtectedRoute = () => {
    const [signedin, setSignedIn] = useState(false);
    const [usertype, setUserType] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

        const checkToken = () => {
            const userToken = localStorage.getItem('data');
           
           
            
            if(!userToken || userToken === 'undefined'){
                setSignedIn(false);
                return navigate('/');
            }

            setSignedIn(true);
            setUserType(JSON.parse(userToken));
            
        }

        useEffect(() => {
            setLoading(true);
            checkToken();
            setLoading(false);
        }, [signedin]);
        
       
    return (
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }

        {
            
            
            (signedin && (usertype.type==="2" || usertype.type===2)) ? <Dashboard /> : <h1 className="text-dark text-center m-5 py-5"></h1>
        }
            
        </>
    );
    
};

export default AdminProtectedRoute;