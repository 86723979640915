import axios from 'axios';
import React,{ useState,useEffect } from 'react';
import {Button,Form,InputGroup } from 'react-bootstrap';
import {BiSolidLock} from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import {BsFillEyeFill,BsFillEyeSlashFill} from 'react-icons/bs';
import {FaTelegramPlane,FaEnvelope} from 'react-icons/fa';
import IMAGES from '../assets/images/IMAGES';
import Alert from 'react-bootstrap/Alert';

const LoginComponent = () => {
    
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [allEntry, setAllEntry] = useState([]);
    const [passType, setPassType] = useState('password');
    const [rememberme, SetRememberMe] = useState("");

    //const [validated, setValidated] = useState(false);

    useEffect(() =>{
      localStorage.removeItem('data');
      localStorage.removeItem("token");
    },[]);

    let navigate = useNavigate();

    const passwordToggle = () => {
      if (passType === 'password'){
        setPassType('text');
      }else {
      setPassType('password');
      }
    }

    

    const handleSubmit = (e) => {
      e.preventDefault();
      
      localStorage.removeItem('data');
      localStorage.removeItem("token");
        

        const formElement = document.querySelector('#login-form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);
        const btnPointer = document.querySelector('#loginbtn');
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);

        


      if(document.getElementById('userName').value === "" || document.getElementById('userPassword').value === ""){
        setError("Email & Password both fields are required");
        btnPointer.innerHTML = 'Sign In <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
			document.querySelector('#login-btn-img').setAttribute('class','ms-1');
            btnPointer.removeAttribute('disabled');
            return false;
        
      } else {
        setUserName("");
        setPassword("");
      }

      

      const loginData = {
        email:username,
        password:password,

      };

      axios.post('http://107.180.103.25:420/entsp-goldenrule/public/api/login', loginData, formDataJSON).then((response) => {
        console.log(response.data);
        if(response.data.status===0 || response.data.status==='0'){
          setError(response.data.msg);
          btnPointer.innerHTML = 'Sign In <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
          document.querySelector('#login-btn-img').setAttribute('class','ms-1');
          btnPointer.removeAttribute('disabled');
        }
        else{
          localStorage.setItem("token",JSON.stringify(response.data.token));
         localStorage.setItem("data",JSON.stringify(response.data));
        }
        
        
       
        
        
        if (response.data.type===1 || response.data.type==="1") {
            return navigate("/superadmin/dashboard");
        } 
        
        else if (response.data.type===2 || response.data.type==="2"){
            return navigate("/admin/dashboard")
        } 
        
        else if (response.data.type===3 || response.data.type==="3"){
          return navigate("/callcenter");
       }

        else {
        return navigate("/");
      }
        

      }).catch((error) => {
        setError(error.response.data.msg);
        console.log(error.response.data.msg);
        btnPointer.innerHTML = 'Sign In <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
			  document.querySelector('#login-btn-img').setAttribute('class','ms-1');
        btnPointer.removeAttribute('disabled');
      })
      

    }

    useEffect(() => {
      const userTypeVal = localStorage.getItem('data');
      if(userTypeVal){
        const userType = JSON.parse(userTypeVal);
        if(userType.type===1 || userType.type==="1"){
           navigate('/superadmin/dashboard');
        }

        else if(userType.type===2 || userType.type==="2"){
          navigate('/admin/dashboard');
        }

        else if(userType.type===3 || userType.type==="3"){
          navigate('/callcenter');
      }
        else{
          navigate('/');
      }

      }
       console.log(allEntry); 
     }, [allEntry,navigate]);

    

     
 
   
return (
  <>
  

  {error ? <Alert variant="danger">{error}</Alert> : ""}

  <Form className="register-form" id="login-form" wasValidated noValidate onSubmit={handleSubmit}>
    <div className="mb-3">
      <InputGroup className="mb-4">
        <Form.Label><FaEnvelope size={15} /> Email</Form.Label>
        <Form.Control id="userName"
          required
          type='email'
          value={username}
          autoComplete='off'
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Please Enter Your User Name"
        
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
              Please choose a username.
            </Form.Control.Feedback>
      </InputGroup >

      <InputGroup className="mb-4">
        <Form.Label><BiSolidLock size={19} /> Password</Form.Label>
        <Form.Control id="userPassword"
          required
          type={passType}
          value={password}
          autoComplete='off'
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Please Enter Your password"
        
        />
        <span className='position-absolute top-50' style={{'cursor':'pointer', 'zIndex':'9', 'right':'15px'}} onClick={passwordToggle}>
          {passType === 'password' ? <BsFillEyeSlashFill size={18} /> : <BsFillEyeFill size={18} />}
        </span>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
              Please choose a Password.
            </Form.Control.Feedback>

      </InputGroup >
      
    </div>
    <InputGroup  className="mb-5 label-agree-term">
      <Form.Check
        
        label="Remember Me?"
      />
    </InputGroup >
    <Button id='loginbtn' variant="link" type="submit">Sign In <FaTelegramPlane className='foms-1 ms-1' size={23} /></Button>
      
    
    <div className="error mt-3">{setAllEntry}</div>

   
  </Form>
  </>
);
       
};

export default LoginComponent;