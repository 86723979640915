import React from 'react';
import { Route , Routes, BrowserRouter} from 'react-router-dom';
import Login from '../pages/login/Login';
//import Dashboard from './pages/dashboard/Dashboard';
import NotFound from '../layouts/NotFound';
import CallCenter from '../pages/Callcenter/CallCenter';
// Admin from './pages/Admin/Admin';
//import SuperAdmin from './pages/SuperAdmin';
import SuperAdminProtectedRoute from './SuperAdminProtectedRoute';
import AdminProtectedRoute from './AdminProtectedRoute';
import UserProtectedRoute from './UserProtectedRoute';
import AdminDashboard from '../pages/Admin/AdminDashboard';
import AdminCodingGuidelines from '../pages/Admin/AdminCodingGuidelines';
import AddAdminUser from '../pages/Admin/AddAdminUser';
import InsuranceLists from '../pages/Admin/InsuranceLists';
import InsuranceEdit from '../pages/Admin/InsuranceEdit';
import CategoryEdit from '../pages/Admin/CategoryEdit';
import SuperAdminDashboard from '../pages/SuperAdmin/SuperAdminDashboard';
import UserManagement from '../pages/SuperAdmin/UserManagement';
import ManageCodingGuideLines from '../pages/Admin/ManageCodingGuideLines';
import EditInsuranceGroup from '../pages/Admin/EditInsurancesGroup';

const GeneralRoute = () => {
    return (
        <>
        <Routes>
            <Route path='/' element={<Login />} />
             <Route path='/not-found' element={<NotFound />} />
        </Routes>
        </>
    );
}
const SuperAdminRoute = () => {
    return (
        <>
        <Routes>
            <Route  element={<SuperAdminProtectedRoute />}>
        
                <Route path='dashboard' element={<SuperAdminDashboard />} />
                <Route path='user-management' element={<UserManagement />} />
                <Route path='coding-guidelines' element={<AdminCodingGuidelines />} />
                <Route path='add-admin-user' element={<AddAdminUser />} />
               <Route path='insurance-lists' element={<InsuranceLists />} />
                <Route path='insurance-edit/:id' element={<InsuranceEdit />} />
                <Route path='category-edit/:id' element={<CategoryEdit />} />
                <Route path='manage-coding-guidelines/:id' element={<ManageCodingGuideLines />} />
                <Route path='edit-insurance-group/:id' element={<EditInsuranceGroup />} />
            </Route>
            </Routes>
        </>
    );
}
const AdminRoute = () => {
    return (
        <>
        <Routes>
            <Route element={<AdminProtectedRoute />}>
                <Route path='dashboard' element={<AdminDashboard />} />
                <Route path='coding-guidelines' element={<AdminCodingGuidelines />} />
                <Route path='add-admin-user' element={<AddAdminUser />} />
                <Route path='insurance-lists' element={<InsuranceLists />} />
                <Route path='insurance-edit/:id' element={<InsuranceEdit />} />
                <Route path='category-edit/:id' element={<CategoryEdit />} />
                <Route path='manage-coding-guidelines/:id' element={<ManageCodingGuideLines />} />
                <Route path='edit-insurance-group/:id' element={<EditInsuranceGroup />} />
            </Route>
        </Routes>
        </>
    );
}
const CallCenterRoute = () => {
    return (
        <>
            <Routes>
                <Route element={<UserProtectedRoute />}>
                    <Route path='/' element={<CallCenter />} />
                </Route>
            </Routes>
        </>
    );
}
// const RoutesMain = () => {
//     return (
//         <>
//             <Routes>
//       <Route path='/' element={<Login />} />
//       <Route path='/not-found' element={<NotFound />} />
      
//         <Route  element={<SuperAdminProtectedRoute />}>
        
//           <Route path='/superadmin/dashboard' element={<SuperAdminDashboard />} />
//           <Route path='/superadmin/coding-guidelines' element={<AdminCodingGuidelines />} />
//           <Route path='/superadmin/add-admin-user' element={<AddAdminUser />} />
//           <Route path='/superadmin/insurance-lists' element={<InsuranceLists />} />
//           <Route path='/superadmin/insurance-edit/:id' element={<InsuranceEdit />} />
//           <Route path='/superadmin/category-edit/:id' element={<CategoryEdit />} />
          
//         </Route>
        
//         <Route element={<AdminProtectedRoute />}>
//           <Route path='/admin/dashboard' element={<AdminDashboard />} />
//           <Route path='/admin/admin-coding-guidelines' element={<AdminCodingGuidelines />} />
//           <Route path='/admin/add-admin-user' element={<AddAdminUser />} />
//           <Route path='/admin/insurance-lists' element={<InsuranceLists />} />
//           <Route path='/admin/insurance-edit/:id' element={<InsuranceEdit />} />
//           <Route path='/admin/category-edit/:id' element={<CategoryEdit />} />
//         </Route>

//         <Route element={<UserProtectedRoute />}>
        
//           <Route path='/dashboard/callcenter' element={<CallCenter />} />
          
//         </Route>
//       </Routes>


      
//         </>
//     );
// };

// export default RoutesMain;

export { GeneralRoute, SuperAdminRoute, AdminRoute, CallCenterRoute };