import React, { useEffect, useState } from 'react';
import {FaUserEdit, FaEdit, FaLock, FaStarOfLife, FaPhoneAlt, FaTelegramPlane} from 'react-icons/fa';
import {MdDelete, MdMail} from 'react-icons/md';
import {Button,Form,InputGroup, Stack,Row, Col, Modal } from 'react-bootstrap';
import Auth from '../../utility/AuthConfig';
import {BiSolidMessageSquareAdd, BiSolidUser} from 'react-icons/bi';
import {PiGenderIntersexBold} from 'react-icons/pi';
import { Link } from 'react-router-dom';
import {BsFillEyeFill,BsFillEyeSlashFill} from 'react-icons/bs';

function UserManagement(){
	
	/**Set States**/
	const [users, setuserLists] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOpen, setisOpen] = useState(false);
	const [isOpeneditmodal, setisOpeneditmodal] = useState(false);
	const [showerrmodal, setShowerrmodal] = useState(false);
	const [fname, setFname] = useState("");
	const [lname, setLname] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState("");
	const [type, setType] = useState("");
	const [userid, setUserid] = useState("");
	const [active, setActive] = useState("");
	const [modalError, setmodalError] = useState("");
	const [success, setSuccess] = useState("");
	const [passType, setPassType] = useState('password');

	const passwordToggle = () => {
		if (passType === 'password'){
		  setPassType('text');
		}else {
		setPassType('password');
		}
	  }
	
	/**Call API for all user list**/
    useEffect(() => {
		setLoading(true);
        Auth.get('/userlist').then((response) => {
            console.log(response.data.data);
            setuserLists(response.data.data);
			setLoading(false);
        }).catch(error => {
			if(error.code === 'ERR_BAD_REQUEST'){
			 console.log('error');
			 localStorage.removeItem('data');
             localStorage.removeItem("token");
		   window.location.pathname = '/'
			}
	  
		});
	},[]);
	
	/**function for valid email check**/
	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	}
	
	/**When add user button click**/
	function openModal(){
		setmodalError("");
		setEmail("");
		setFname("");
		setLname("");
		setPhone("");
		setGender("");
		setType("");
		setUserid("");
		setActive("");
		setisOpen(true);
	}
	
	/**When add user close button click**/
	function closeModal(){
		setmodalError("");
		setisOpen(false);
	}
	
	/**When edit user close button click**/	
	function closeeditModal(){
		setmodalError("");
		setisOpeneditmodal(false);
	}
	
	/**Register api calls**/	
	function addUser(){
		if(fname==''){
			setmodalError('Please enter first name');
		} else if(lname=='') {
			setmodalError('Please enter last name');
		} else if (email=='') {
			setmodalError('Please enter email address');
		} else if (!isValidEmail(email)) {
			setmodalError('Please enter valid email address');
		} else if (password == '') {
			setmodalError('Please enter password');
		} else if (password.length < 8) {
			setmodalError('Enter password minimum 8 charecters long');
		} else if (type == '') {
			setmodalError('Please select user type');
		} else if (phone == '' || phone.length != 10) {
			setmodalError('Please enter 10 digit phone number');
		} else{
			setLoading(true);
			const userData = {
			phone:phone,
			password:password,
			email:email,
			type:type,
			firstname:fname,
			lastname:lname,
			gender:gender
			};
			Auth.post('/register', userData).then((response) => {  
				setLoading(false);
				if(response.data.status==1){
					setisOpen(false);
					setSuccess(response.data.msg);
					setTimeout(function() {
						window.location.reload();
					}, 1000);
				}else{
					setmodalError(response.data.msg);
				}
			}).catch(error => {
				if(error.code === 'ERR_BAD_REQUEST'){
				 console.log('error');
				 localStorage.removeItem('data');
                 localStorage.removeItem("token");
			   window.location.pathname = '/'
				}
		  
			});
		}
	}
	
	/**delete user api calls**/
	function deleteUser(id){
		setLoading(true);
		Auth.post('/deleteuser/'+id, []).then((response) => {  
				setLoading(false);
				if(response.data.status==1){
					setSuccess(response.data.msg);
				}else{
					setmodalError(response.data.msg);
				}
			}).catch(error => {
			if(error.code === 'ERR_BAD_REQUEST'){
				localStorage.removeItem('data');
             localStorage.removeItem("token");
				setShowerrmodal(true);
			}
			})
	}
	
	/**edit user get api calls**/
	function editUser(id){
		setmodalError("");
		setEmail("");
		setFname("");
		setLname("");
		setPhone("");
		setGender("");
		setType("");
		setUserid("");
		setisOpeneditmodal(true);
		setLoading(true);
		Auth.get('/singleuser/'+id).then((response) => {
            console.log(response.data); 
			setEmail(response.data.EmailAddress);
			setFname(response.data.FirstName);
			setLname(response.data.Lastname);
			setPhone(response.data.UserPhone);
			setGender(response.data.Gender);
			setType(response.data.UserTypeID);
			setUserid(response.data.UserID);
			setActive(response.data.Active);
			setLoading(false);
        }).catch(error => {
			if(error.code === 'ERR_BAD_REQUEST'){
			 console.log('error');
			 localStorage.removeItem('data');
             localStorage.removeItem("token");
		   window.location.pathname = '/'
			}
	  
		});
	}
	
	/**update user api calls**/
	function updateUser(id){
		setmodalError("");
		if(fname==''){
			setmodalError('Please enter first name');
		} else if(lname=='') {
			setmodalError('Please enter last name');
		} else if (type == '') {
			setmodalError('Please select user type');
		} else if (phone == '' || phone.length != 10) {
			setmodalError('Please enter 10 digit phone number...');
		} else{
			setLoading(true);
			const userData = {
			phone:phone,
			email:email,
			type:type,
			firstname:fname,
			lastname:lname,
			gender:gender,
			Active:active
		};
			Auth.post('/updateuser/'+id, userData).then((response) => {  
				setLoading(false);
				if(response.data.status==1){
					setisOpeneditmodal(false);
					setSuccess(response.data.msg);
					setTimeout(function() {
						window.location.reload();
					}, 1000);
				}else{
					setmodalError(response.data.msg);
				}
			}).catch(error => {
			if(error.code === 'ERR_BAD_REQUEST'){
				//localStorage.clear();
				localStorage.removeItem('data');
                localStorage.removeItem("token");
				setShowerrmodal(true);
			}
			})
		}
	} 
	
	/**edit modal checkbox toggle value set **/
	function handlecheckbox(e){
		let value = e.currentTarget.value;
		if(value == 0){
			setActive(1);
		}else if(value == 1){
			setActive(0);
		}
	} 
	
	/**when unauthenticated msg comes **/
	function handleerrmodalClose(){
		setLoading(false);
		setShowerrmodal(false);
		setTimeout(function() {
			window.location.pathname = '/'
		}, 1000);
	}
    return (
		<>
		<div className="bg-white shadow-lg py-4 px-4 rounded-4">
			<div className="mb-3">
				<Row>
					<Col lg={6} md={12}>
						<h3>User Management</h3>
					</Col>
					<Col lg={6} md={12} className="text-end">
						
						<Link onClick={openModal} className='btn btn-primary'><BiSolidMessageSquareAdd className='me-2' size={20} /> Add User </Link>
					</Col>
				</Row>
			</div>
		{
			(loading) ? (
				<div className="d-flex justify-content-center">
					<div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
				</div>
			) : (
				null
			)
		}
		{
			  (success) ? (
				<div className="alert alert-success" role="alert">{success}</div>
			   ) : (
					null
				)
			}
			{
			  (modalError) ? (
				<div className="alert alert-danger" role="alert">{modalError}</div>
			   ) : (
					null
				)
			}
			<div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
				<div className="table-responsive tableFixHead">
					<table className="table table-striped">
						<thead>
							<tr>
								<th scope="col">#ID</th>
								<th scope="col">First Name</th>
								<th scope="col">Last Name</th>
								<th scope="col">Email Address</th>
								<th scope="col">Roll Type</th>
								<th scope="col">Status</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
						{
							users.map((user,i)=>
								<tr key={i}>
									<th scope="row">{i+1}</th>
									<td>{user.FirstName}</td>
									<td>{user.Lastname}</td>
									<td>{user.EmailAddress}</td>
									<td>
									{
										(user.UserTypeID === 1 || user.UserTypeID === "1") && <span className="text-success fw-bold">Super Admin</span>
									}
									{
										(user.UserTypeID === 2 || user.UserTypeID === "2") && <span className="text-warning fw-bold">Admin</span>
									}
									{
										(user.UserTypeID === 3 || user.UserTypeID === "3") && <span className="text-primary fw-bold">User</span>
									}
									</td>
									<td>
									{
										(user.Active === 1 || user.Active === "1") ? (
											<span style={{'color':'#32CD32', 'fontWeight':'600'}}>Active</span>
										) : (
											<span style={{'color':'red','fontWeight':'600'}}>Inactive</span>
										)
									}
									</td>
									<td><FaEdit style={{'color':'#3f80ea', 'cursor':'pointer'}} size={20} onClick={(e) => { editUser(user.UserID) }} /> | 
									<MdDelete style={{'color':'red', 'cursor':'pointer','pointerEvents':'none','userSelect':'none','opacity':'0.5'}} size={20} onClick={(e) => { window.confirm( 'Are you sure you want to delete this user?', ) && deleteUser(user.UserID) }} />
									</td>
								</tr>
							)
						}
							
						</tbody>
					</table>
				</div>
			</div>
		</div>
		{/*-- Add Modal--*/}
		<Modal show={isOpen} onHide={closeModal} aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add User {userid}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='signin-form inner-form pt-1'>
		  {(loading) ? (
				<div className="d-flex justify-content-center">
					<div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
				</div>
			) : (
				null
		  )}
		  {
			  (modalError) ? (
				<div className="alert alert-danger" role="alert">{modalError}</div>
			   ) : (
					null
				)
			}
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><BiSolidUser size={22} />First Name <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='text'
						autoComplete='off'
						onChange={(e)=>{setFname(e.target.value)}}
						value={fname}
						placeholder="Please Enter First Name"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><BiSolidUser size={22} />Last Name <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='text'
						autoComplete='off'
						onChange={(e)=>{setLname(e.target.value)}}
						value={lname}
						placeholder="Please Enter Last Name"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><MdMail size={22} />E-mail <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='email'
						autoComplete='off'
						onChange={(e)=>{setEmail(e.target.value)}}
						value={email}
						placeholder="Please Enter E-mail"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><FaLock size={22} />Password <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type={passType}
						autoComplete='off'
						onChange={(e)=>{setPassword(e.target.value)}}
						value={password}
						placeholder="Password"/>

<span className='position-absolute top-50' style={{'cursor':'pointer', 'zIndex':'9', 'right':'15px', 'color':'#1886EB'}} onClick={passwordToggle}>
          {passType === 'password' ? <BsFillEyeSlashFill size={18} /> : <BsFillEyeFill size={18} />}
        </span>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><FaPhoneAlt size={18} />Phone <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='text'
						autoComplete='off'
						onChange={(e)=>{setPhone(e.target.value)}}
						value={phone}
						placeholder="Please Enter Phone"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2 custom-select">
						<Form.Label><PiGenderIntersexBold size={22} />Gender <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Select
                    onChange={(e)=>{setGender(e.target.value)}}
                    aria-label="Default select example">
                    <option value="">Select Gender</option>
						<option value="M">Male</option>
						<option value="F">Female</option>
                    </Form.Select>
					</InputGroup >
				</Col>	
			</Row>
			
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2 custom-select">
						<Form.Label><FaUserEdit size={22} />User Type <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Select
                    onChange={(e)=>{setType(e.target.value)}}
                    aria-label="Default select example">
					<option value="">Select user type</option>
						<option value="1">Super Admin</option>
						<option value="2">Admin</option>
						<option value="3">User</option>
                    </Form.Select>
					</InputGroup >
				</Col>	
			</Row>
			
			
		  </Modal.Body>
		 
          <Modal.Footer>
		
			<Button variant='link' onClick={addUser}><FaTelegramPlane className='foms-1 ms-1' size={23} /> Submit</Button>
		
          </Modal.Footer>
        </Modal>
		{/*-- Edit Modal--*/}
		<Modal show={isOpeneditmodal} onHide={closeeditModal} aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body className='signin-form inner-form pt-1'>
			{(loading) ? (
				<div className="d-flex justify-content-center">
					<div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
				</div>
			) : (
				null
		  )}
		  {
			  (modalError) ? (
				<div className="alert alert-danger" role="alert">{modalError}</div>
			   ) : (
					null
				)
			}
			

			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><BiSolidUser size={22} />First Name <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='text'
						autoComplete='off'
						onChange={(e)=>{setFname(e.target.value)}}
						value={fname}
						placeholder="Please Enter First Name"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><BiSolidUser size={22} />Last Name <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='text'
						autoComplete='off'
						onChange={(e)=>{setLname(e.target.value)}}
						value={lname}
						placeholder="Please Enter Last Name"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><MdMail size={22} />E-mail <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='email'
						autoComplete='off'
						onChange={(e)=>{setEmail(e.target.value)}}
						value={email}
						placeholder="Please Enter E-mail" disabled />
					</InputGroup >
				</Col>	
			</Row>
			{/* <Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><FaLock size={22} />Password <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type={passType}
						autoComplete='off'
						onChange={(e)=>{setPassword(e.target.value)}}
						value={password}
						placeholder="Password"/>

					<span className='position-absolute top-50' style={{'cursor':'pointer', 'zIndex':'9', 'right':'15px', 'color':'#1886EB'}} onClick={passwordToggle}>
							{passType === 'password' ? <BsFillEyeSlashFill size={18} /> : <BsFillEyeFill size={18} />}
							</span>

					</InputGroup >
				</Col>	
			</Row> */}
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2">
						<Form.Label><FaPhoneAlt size={18} />Phone <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Control 
						required
						type='text'
						autoComplete='off'
						onChange={(e)=>{setPhone(e.target.value)}}
						value={phone}
						placeholder="Please Enter Phone"/>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-2 custom-select">
						<Form.Label><PiGenderIntersexBold size={22} />Gender <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Select value={gender}
                    onChange={(e)=>{setGender(e.target.value)}}
                    aria-label="Default select example">
                    <option value="">Select Gender</option>
						<option value="M">Male</option>
						<option value="F">Female</option>
                    </Form.Select>
					</InputGroup >
				</Col>	
			</Row>
			
			<Row className='align-items-center'>
				<Col md={12}>
					<InputGroup className="mb-4 custom-select">
						<Form.Label><FaUserEdit size={22} />User Type <FaStarOfLife style={{'color':'red'}} size={7} /></Form.Label>
						<Form.Select value={type}
                    onChange={(e)=>{setType(e.target.value)}}
                    aria-label="Default select example">
					<option value="">Select user type</option>
						<option value="1">Super Admin</option>
						<option value="2">Admin</option>
						<option value="3">User</option>
                    </Form.Select>
					</InputGroup >
				</Col>	
			</Row>
			<Row className='align-items-center'>
				<Col md={12}>
					<fieldset>
						<Form.Group  className="d-flex align-items-center  custom-check">
						<Form.Label className='me-3'>Active</Form.Label>
							<div className="form-check">
							<Form.Check type="checkbox" onChange={handlecheckbox} value={active} checked={(active=="1") ? "checked" : "" } />
							</div>
						</Form.Group>
					</fieldset>
				</Col>
			</Row>
		  </Modal.Body>
		 
          <Modal.Footer>
		
			<Button variant='link' onClick={(e) => { updateUser(userid) }}><FaTelegramPlane className='foms-1 ms-1' size={23} /> Submit</Button>
		
          </Modal.Footer>
        </Modal>
		{/*-- Error Modal--*/}
		<Modal show={showerrmodal} onHide={handleerrmodalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your session has timed out. Please login again.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleerrmodalClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
		</>
    );
};

export default UserManagement;