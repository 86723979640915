import Auth from '../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {Button,Form,InputGroup, Stack,Row, Col } from 'react-bootstrap';
import {GiCancel} from 'react-icons/gi';
import {FaTelegramPlane,} from 'react-icons/fa';
import {BiSolidUserDetail} from 'react-icons/bi';
import {FaUsersLine} from 'react-icons/fa6';
import {RiParentFill} from 'react-icons/ri';
import Alert from 'react-bootstrap/Alert';
import IMAGES from '../assets/images/IMAGES';


const AddAdminUserForm = () => {

    const [groups, setGroups] = useState([]);
    const [types, setTypes] = useState([]);
    const [cats, setCats] = useState([]);
    const [loading, setLoading] = useState(false);

    const token = JSON.parse(localStorage.getItem('token'));
    
    useEffect(() =>{
         /**GET CATEGORY GROUP**/
         setLoading(true);
         Auth.get('/getcategorygroup').then((response) => {
                setGroups(response.data.groups);
                //console.log(response.data);
                setLoading(false); 
            }).catch(error => {
                if(error.code === 'ERR_BAD_REQUEST'){
                 console.log('error');
                 localStorage.removeItem('data');
                 localStorage.removeItem("token");
               window.location.pathname = '/'
                }
          
            });
     
     /**GET DATA TYPE**/
     setLoading(true);
     Auth.get('/getdatatype').then((response) => {
            setTypes(response.data.types);
            //console.log(response.data);
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
         /**GET CATEGORY LIST**/
         setLoading(true);
         Auth.get('/getallcategories').then((response) => {
            setCats(response.data.categories);
            //console.log(response.data);
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
 },[]);

    const [catname, setCatName] = useState("");
    const [catgroup, setCatGroup] = useState("");
    const [parentcat, setParentCat] = useState("");
    const [datatype, setDataType] = useState("");
    const [actinact, setActInact] = useState("");
    const [endisable, setEnDisable] = useState("");
    const [error, setError] = useState("");
    const [allEntry, setAllEntry] = useState([]);
    const [success, setSuccess] = useState("");
    
    const userTypeVal = localStorage.getItem('data');
    const userid = JSON.parse(userTypeVal);
    

 const AddCategorySubmit = (e) => {
    e.preventDefault();

    //const userTypeVal = localStorage.getItem('data');
    const addCategoryData = {
        category_name:catname,
        parent_category_id:parentcat,
        category_group_id:catgroup,
        data_type:datatype,
        is_cat_edit_active:actinact,
        created_by:userid.id

    };



    //   const formElement = document.querySelector('#adduser-form');
    //   const formData = new FormData(formElement);
    //   const formDataJSON = Object.fromEntries(formData);
      const btnPointer = document.querySelector('#submitbtn');
      btnPointer.innerHTML = 'Please wait..';
      btnPointer.setAttribute('disabled', true);


    if(document.getElementById('catname').value === "" || document.getElementById('catgroup').value === ""){
        
      setError("Please add category name & choose category field & data type");
      btnPointer.innerHTML = 'Submit <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
          document.querySelector('#login-btn-img').setAttribute('class','ms-1');
          btnPointer.removeAttribute('disabled');
          
          return false;
         
      
    } else {
        
      
    }

    

    
    // const config = {
    //     headers: { 
    //         Authorization: `Bearer ${token}`,
    //         'Accept' : 'application/json',
    //         'Content-Type': 'application/json' 
    //     }
    // };
    
    /**ADD CATEGORY DATA**/
    
    Auth.post('/add-category', addCategoryData).then((response) => {
      console.log(response.data);
      if(response.data.status===0 || response.data.status==='0'){
        setError(response.data.msg);
        btnPointer.innerHTML = 'Submit <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
        document.querySelector('#login-btn-img').setAttribute('class','ms-1');
        btnPointer.removeAttribute('disabled');
        console.log(allEntry);
        setLoading(false); 
      }
      else{
        setError("");
        setSuccess(response.data.msg);
        btnPointer.innerHTML = 'Submit <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
        document.querySelector('#login-btn-img').setAttribute('class','ms-1');
        btnPointer.removeAttribute('disabled');
        setTimeout(function() {
            window.location.replace('./coding-guidelines');
        }, 2000);
      }
      
      
     
      
      
     
      

    }).catch((error) => {
      setError(error.response.data.msg);
      console.log(error.response.data.msg);
      btnPointer.innerHTML = 'Submit <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
        document.querySelector('#login-btn-img').setAttribute('class','ms-1');
        btnPointer.removeAttribute('disabled');
    })
    

  }

    return (
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
        <Stack>

        {success ? <Alert variant="success">{success}</Alert> : ""}

        {error ? <Alert variant="danger">{error}</Alert> : ""}

<Form className="signin-form inner-form" id="adduser-form" noValidate onSubmit={AddCategorySubmit}>
    <div className="mb-3">
        <Row>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4">
                    <Form.Label><BiSolidUserDetail size={22} /> Category Name</Form.Label>
                    <Form.Control id="catname"
                    required
                    type='text'
                    value={catname}
                    autoComplete='off'
                    onChange={(e) => setCatName(e.target.value)}
                    
                    placeholder="Please Enter Category Name"/>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Please choose a Category.
                        </Form.Control.Feedback>
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4 custom-select">
                    <Form.Label><FaUsersLine size={22} /> Category Group</Form.Label>
                    <Form.Select
                    required
                    id="catgroup"
                    onChange={(e) => setCatGroup(e.target.value)}
                    aria-label="Default select example">
                        <option>Open this select menu</option>
                        {
                            groups.map((group,i) => {
                                return <option key={i} value={group.Value}>{group.Name}</option>
                            })
                        }

                    
                    
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Please choose a Category Group.
                    </Form.Control.Feedback>
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4 custom-select">
                    <Form.Label><RiParentFill size={22} /> Parent Category</Form.Label>
                    <Form.Select
                    id="parentcat"
                    onChange={(e) => setParentCat(e.target.value)} 
                    aria-label="Default select example">
                    <option>Open this select menu</option>
                    {
                            cats.map((cat,i) => {
                                return <option key={i} value={cat.ID}>{cat.Name}</option>
                            })
                        }
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Please choose a Parent Category.
                    </Form.Control.Feedback>
                </InputGroup >
            </Col>
        </Row>
        <Row>
            <Col md={12}>
            <fieldset>
                <Form.Group  className="mb-3 d-flex align-items-center customradio ">
                
                <Form.Label className='me-3'>Data Type</Form.Label>

                        {
                            types.map((type,i) => {
                                return <Form.Check
                                key={i}
                                inline
                                required
                                label={type.Name}
                                name="datatype"
                                type="radio"
                                id={type.Value}
                                value={type.Value}
                                checked={datatype === type.Value}
                                onChange={(e) => setDataType(e.target.value)}
                            />
                            })
                        }

                    
                </Form.Group>
      </fieldset>
            </Col>
            <Col md={12}>
                <fieldset>
                    <Form.Group className="mb-3 d-flex align-items-center customradio">
                    
                    <Form.Label className='me-3'>Active / Inactive</Form.Label>

                       
                    <Form.Check
                            inline
                            label="Yes"
                            name="catstatus"
                            type="radio"
                            id="active"
                            value="1"
                            
                            onChange={(e) => setActInact(e.target.value)}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="catstatus"
                            type="radio"
                            id="inactive"
                            value="0"
                            onChange={(e) => setActInact(e.target.value)}
                        />
                        
                    </Form.Group>
                   
            </fieldset>
            </Col>
            <Col md={12}>
                <fieldset>
                    <Form.Group className="mb-3 d-flex align-items-center customradio">
                    <Form.Label className='me-3'>Enable to add data from excel sheet</Form.Label>
                    <Form.Check
                            inline
                            label="Yes"
                            name="excelsh"
                            type="radio"
                            id="enable"
                            value="1"
                            onChange={(e) => setEnDisable(e.target.value)}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="excelsh"
                            type="radio"
                            id="disable"
                            value="0"
                            onChange={(e) => setEnDisable(e.target.value)}
                        />
                       
                    </Form.Group>
        </fieldset>
            </Col>
        </Row>
      
      
      
      



    </div>
      <Row className='justify-content-center'>
        <Col sm={3} className='d-flex '>
        <Button id='submitbtn' variant="link" type="submit" className='me-2'><FaTelegramPlane className='foms-1 ms-1' size={23} /> Submit</Button>
        
        <Button id='cancelbtn' variant="secondary" type="button" onClick={()=>window.location.replace('./coding-guidelines')}><GiCancel className='foms-1 ms-1' size={23} /> Cancel</Button>
        </Col>
    </Row>

   
  </Form>  

 
  <div className="entry"><p className='mt-3 d-inline-block'>{setAllEntry}</p></div>
  
  </Stack>
        </>
    );
};
export default AddAdminUserForm;