import React, {useState, useEffect} from 'react';
import Auth from '../../utility/AuthConfig';
import EditInsuranceGroupForm from '../../components/EditInsuranceGroupForm';


const EditInsuranceGroup = () => {
    const [loading, setLoading] = useState(false);
    const [groupname, setGroupName] = useState("");

    const windowUrl = window.location.href;
	var res = windowUrl.split("/");
	var pos = res.indexOf('edit-insurance-group');
	var last_segment = res[pos+1];

    useEffect(() =>{
		setLoading(true);
		Auth.get('/insurance-edit-group-wise/'+last_segment).then((response) => {
           
            console.log(response.data.insurancedetails);
            setGroupName(response.data.insurancedetails[0]);
			//console.log(response.data.fields);
			
			setLoading(false); 
		}).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
    
	},[]);
   

   

    return(
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
        <div>
            <div className="bg-white shadow-lg py-4 px-4 rounded-4">
            <div className="d-flex align-items-center mb-3">
                    <div className="col-auto d-block">
                        <h2 className="table-title text-dark fw-semibold fs-3">{groupname.InsuranceName}</h2>
                    </div>
                    </div>        
                  <EditInsuranceGroupForm />
                
            </div>
            
        </div>
        </>
    )
}

export default EditInsuranceGroup;