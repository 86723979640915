import React from 'react';
import Header from './Header';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Footer';

const NotFound = () => {
    return (
        <>
            <Header />
            <Container>
                <Row>
                    <Col><h1>Unauthorised Login</h1></Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default NotFound;