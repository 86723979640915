import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Sidebar from '../../layouts/Sidebar';
import MainBody from '../../layouts/MainBody';
import IMAGES from '../../assets/images/IMAGES';



const Dashboard = () => {
    const [expand, isExpand] = useState(true);
    const expandSidebar = () => {
        isExpand(!expand);
      }
const [userProfile, setUserProfile] = useState({});



useEffect(() => {
  

    const profileName = localStorage.getItem('data');
    setUserProfile(JSON.parse(profileName));
   
},[]);



const handleLogout = () => {

  const token = JSON.parse(localStorage.getItem('token'));

  const headers = { Authorization: `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' };

//   axios.post('http://107.180.103.25:420/entsp-goldenrule/public/api/logout', {}, headers).then((response) => {
//         console.log(response.data);

//   localStorage.clear();
//   window.location.pathname = '/frontend'
// });

fetch('http://107.180.103.25:420/entsp-goldenrule/public/api/logout',{ method: 'POST', headers }).then((response)=>{
				response.json().then((result)=>{
					console.log(result.data);
          localStorage.removeItem('data');
          localStorage.removeItem("token");
        window.location.pathname = '/'
				})
			});
    }



    return (
        <>
            
      
      
        <Header onexpandSidebar={expandSidebar} printprofileName={userProfile?.name} showprofileImg={IMAGES.Avatar} onLogOut={handleLogout} />
            <div className="body-slide body-pd" data-sidebar-behavior="sticky">
            <div className="wrapper dashboardSection">
                <Sidebar expand={expand} printprofileName={userProfile?.name} showprofileImg={IMAGES.Avatar} />
                <MainBody expand={expand} onLogOut={handleLogout} />
            </div>
            </div>
      <Footer />
        </>
    );
};

export default Dashboard;