import axios from 'axios';
import Auth from '../../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {FaCheck, FaEdit} from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';
import {FaUpload} from 'react-icons/fa';
import {ImCross} from 'react-icons/im';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Pagination from 'react-bootstrap/Pagination';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';


const InsuranceLists = () => {
    const [insurancelists, setInsuranceLists] = useState([]);
    const [statusmsg, setStatusMsg] = useState("");
    const [dispalymsg, setDisplayMsg] = useState(false);
    const [displaymsgerrins, setDisplayMsgErrIns] = useState(false);
    const [displaymsscssins, setDisplayMsgscssIns] = useState(false);
    const [totalrecords, setTotalRecords] = useState("");
    const [inspagination, setInsPagination] = useState("");
    const [uploadinsurance, setUploadInsurance] = useState([]);
    const [currntpage, setCurrntPage] = useState('');
    const [insurancesucessemsg, setInsuranceSucessMsg] = useState('');
    const [insuranceerroremsg, setInsuranceErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteins, setDeleteIns] = useState("");
    const [deletedisplayins, setDeleteDisplayIns] = useState(false);
    const [showmodal, setShowModal] = useState(false);
    const [ascid, setAscId] = useState("");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
    const token = JSON.parse(localStorage.getItem('token'));

 /**GENERATE HEADER TOKEN**/   
    // const config = {
    //     headers: { 
    //         Authorization: `Bearer ${token}`,
    //         'Accept' : 'application/json',
    //         'Content-Type': 'application/json' 
    //     }
    // };
/**IMPORT HEADER TOKEN**/
    const headers = {
        headers: { 
        Authorization: `Bearer ${token}`,
        'Accept' : 'application/json',
        "Content-Type": "multipart/form-data"
    }
}
   
/**LIST INSURANCE API**/
    useEffect(() => {
        setLoading(true);
        Auth.get('/getinsurancelist/1').then((response) => {
            setInsuranceLists(response.data.insurances);
            setTotalRecords(response.data.resultdetails[0].total_records);
            setInsPagination(response.data.resultdetails[0].total_no_of_pages);
            setCurrntPage(response.data.resultdetails[0].currentPage)
           
            setLoading(false);
            //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });

            

    },[]);


    const userTypeVal = localStorage.getItem('data');
    const userid = JSON.parse(userTypeVal);

/**IMPORT INSURANCE API**/
    const XMLFile = (e) => {
        e.preventDefault();

    
        console.log(uploadinsurance);
        const fd = new FormData();
        fd.append('file', uploadinsurance);
        fd.append('userid', userid.id);
        setLoading(true);
        Auth.post('http://107.180.103.25:420/entsp-goldenrule/public/api/import-insurance', fd, headers).then((response) => {
            setUploadInsurance(response.data);
            
           
            console.log(response.data);
            setLoading(false);
            if(response.data.status === 0 || response.data.status === '0'){
                setInsuranceErrorMsg(response.data.msg);
                setDisplayMsgErrIns(true);
                //console.log(setInsuranceErrorMsg);
                setTimeout(function(){
                    window.location.reload(1);
                 }, 1000);
                
            } else if(response.data.status === 1 || response.data.status === '1'){
                
                setInsuranceSucessMsg(response.data.msg);
                setDisplayMsgscssIns(true);
                setTimeout(function(){
                    window.location.reload(1);
                 }, 1000);
            }
        })
        
    }

/**STATUS INSURANCE API**/
    const activeValue = (e) => {
        let userid = e.currentTarget.dataset.userid;
        console.log(userid);

        let insuranceid = e.currentTarget.dataset.insuranceid;
        console.log(insuranceid);
        let statusid = e.currentTarget.dataset.status;
        console.log(statusid);

        const data = {
            status:statusid,
            userid:userid,
    
          };
          setLoading(true);
          Auth.post('/change-insurance-status/' + insuranceid, data).then((response) => {    
            setStatusMsg(response.data.msg);
            setDisplayMsg(true);
            setLoading(false);
        //console.log(response.data.status);
          if(response.data.status === 1 || response.data.status === '1'){
            setTimeout(function(){
                window.location.reload(1);
             }, 1000);
          } 

        })

        

    }

/**PAGINATION INSURANCE API**/
    const InsPaginationNum = (e) =>{
        let pageid = e.target.dataset.pageid;
        setLoading(true);
        Auth.get('/getinsurancelist/' + pageid).then((response) => {
            setInsuranceLists(response.data.insurances);
            setTotalRecords(response.data.resultdetails[0].total_records);
            setInsPagination(response.data.resultdetails[0].total_no_of_pages);
            setCurrntPage(response.data.resultdetails[0].currentPage)
            setLoading(false);
            console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
        
    }

 /**PAGINATION**/ 
let items = [];
for (let number = 1; number <= inspagination; number++) {
  items.push(
    <Pagination.Item key={number} active={number == currntpage || number === currntpage} data-pageid={number} data-current={currntpage} onClick={InsPaginationNum}>
      {number}
    </Pagination.Item>,
  );
}

/**DELETE INSURANCE API**/
    const deleteInsurance = (e) =>{
    let insuranceid = e.currentTarget.dataset.insuranceid;
   
    setLoading(true);
    Auth.post('/delete-insurance/' + insuranceid, []).then((response) => {    
    setDeleteIns(response.data.msg);    
    
    
    setDeleteDisplayIns(true);
    setShowModal(false)
    setLoading(false);
    
    setTimeout(function(){
    window.location.reload(1);
    }, 1000);

        })
}

    return (
        <>
            { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
           
             
           
           <div className="bg-white shadow-lg py-4 px-4 rounded-4">
            <div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            {
            displaymsscssins ? <Alert variant="success">{insurancesucessemsg}</Alert> : ""
            }

            {
            displaymsgerrins ? <Alert variant="danger">{insuranceerroremsg}</Alert> : ""
            }

            {
            dispalymsg ? <Alert variant="success">{statusmsg}</Alert> : ""
            }

            {
            deletedisplayins ? <Alert variant="success">{deleteins}</Alert> : ""
            }
                <div className="d-flex align-items-center mb-3">
                    <div className="col-auto d-block">
                        <h2 className="table-title text-dark fw-semibold fs-3">Insurance Lists</h2>
                    </div>
                    <div className="col-auto d-block me-auto ms-3 ">
                        <div className="dataTables_length"><h5 className="text-info mb-0">{totalrecords} results found</h5></div>
                    </div>
                                
                                    
                    <div className="col-auto d-block ms-auto ms-3 ">
                    
        <div className="file_upload">
                        <Form id="insuranceForm" onSubmit={XMLFile}>
                        <Form.Group controlId="formFile" className="mb-1 d-flex">
                        <Form.Control type='file' name='file' onChange={(e) => setUploadInsurance(e.target.files[0])} />
                        <input type='hidden' value={userid.id} name='userid'></input>
                        <Button type='submit' variant='primary'><FaUpload size={20} /> </Button>
                        </Form.Group>
                       
                        
                        </Form>
                        <Link to="/files/demo_file.xlsx" target="_blank" download><small className='d-block text-end'>Download sample file</small> </Link>
                        </div>
       
                   
                        
                    </div>
                </div>

                <div className='table-responsive tableFixHead'>
                <table id="datatables-column-search-select-inputs" className="table table-striped dataTable" aria-describedby="datatables-column-search-select-inputs_info">
                    <thead className="thead-dark">
                    <tr role="row">
                    <th className="sorting align-middle text-center">Ref#</th>
                    <th className="sorting align-middle">Name</th>
                    <th className="sorting align-middle">Created</th>
                    <th className="sorting align-middle">Deactivated</th>
                    <th className="align-middle text-center">Status</th>
                    <th className="align-middle text-center">Show In GoldenRule</th>
                    <th className="align-middle text-center">Edit</th>
                    <th className="align-middle text-center">Delete</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        insurancelists.map((insurancelist, i) => {
                            return(
                            
                               
                    
                    <tr role="row" className={`count++ % 2 ? 'odd' : 'even'`} key={i}>
                    <td className="align-middle text-center"><strong>{insurancelist.InsurancesID}</strong></td>
                    <td className="align-middle">{insurancelist.InsuranceName}</td>
                    <td className="align-middle">{insurancelist.StartingDate.substring(0, 11)}</td>
                    <td className="align-middle">{insurancelist.DeactivateDate ? insurancelist.DeactivateDate.substring(0, 11) : ''}</td>
                    <td className="align-middle text-center">{insurancelist.IsActive === "1"  ?  <span style={{'color':'#32CD32', 'cursor':'pointer','font-weight':'600'}}
                     data-status={0} data-userid={userid.id} data-insuranceid={insurancelist.InsurancesID} onClick={activeValue}>Active</span>  :
                    <span data-status={1} data-userid={userid.id} data-insuranceid={insurancelist.InsurancesID} style={{'color':'red','font-weight':'600','cursor':'pointer'}} onClick={activeValue}>Inactive</span>}</td>
                    <td className="align-middle text-center">{insurancelist.IsGoldenRule === "1" ? <FaCheck style={{'color':'#32CD32'}} size={20} /> : <ImCross size={18} style={{'color':'red'}} />}</td>
                    <td className="align-middle text-center"><Link to={"../insurance-edit/" + insurancelist.InsurancesID}>
                    <FaEdit style={{'color':'#3f80ea'}} size={20} /></Link></td>
                    <td className="align-middle text-center"><MdDelete style={{'color':'red', 'cursor':'pointer','pointerEvents':'none','userSelect':'none','opacity':'0.5'}} size={20} data-insuranceid={insurancelist.InsurancesID} onClick={handleShow} />
                    <Modal show={showmodal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Delete Insurance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                    
                    <Modal.Footer>
                    <Button variant='secondary' className='me-2' data-insuranceid={insurancelist.InsurancesID} onClick={deleteInsurance}>Yes</Button>
                    <Button variant="primary" onClick={handleClose}>No</Button>
                    </Modal.Footer>
                    </Modal>
                    </td>

                    
                    </tr>
                        )
                        })
                    }
                    

                    </tbody>
                    </table>

                   <div className='mt-4'><Pagination className='justify-content-end'>{items}</Pagination></div> 
                </div>
            </div>
           </div> 
        </>
    );
};

export default InsuranceLists;