import Auth from '../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {Button,Form,InputGroup, Stack,Row, Col } from 'react-bootstrap';
import {GiCancel} from 'react-icons/gi';
import {FaTelegramPlane, FaUpload, FaHandHoldingMedical} from 'react-icons/fa';
import {BiSolidUserDetail} from 'react-icons/bi';
import {FaUsersLine} from 'react-icons/fa6';
import {RiParentFill} from 'react-icons/ri';
import {MdDelete, MdPictureAsPdf} from 'react-icons/md';
import {HiDocumentAdd} from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import IMAGES from '../assets/images/IMAGES';
import Alert from 'react-bootstrap/Alert';

const AddMoreInsurance = (props) => {
	const [insurancelist, setInsuranceList] = useState([]);
	useEffect(() =>{
        
        /**LIST INSURANCE API**/
        Auth.get('/getallinsurancelist').then((response) => {
            setInsuranceList(response.data.insurances);
            
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
	},[]); 
	
	return (
		<Row className='align-items-center row-mapping-insurance'>
        <Col lg={5} md={12}>
        <InputGroup className="mb-4 custom-select">
                <Form.Label><FaHandHoldingMedical size={22} /> Select An Insurance</Form.Label>
                <Form.Select
                id="selectinsurance"
				className="select-insurance"
                onChange={props.changeHandler} 
                aria-label="Default select example">
                <option value="">Open this select menu</option>
                {
                        insurancelist.map((insurance,i) => {
                            return <option key={i} value={insurance.InsurancesID}>{insurance.InsuranceName}</option>
                        })
                    }
                </Form.Select>
                
            </InputGroup >
        </Col>
        <Col lg={5} md={12}>
        <InputGroup className="mb-4 file_upload">
                <Form.Label><MdPictureAsPdf size={22} /> Upload PDF</Form.Label>
                <Form.Group controlId="formFile" className="mb-1 d-flex" style={{'margin-left':'1px'}}>
                    <Form.Control type='file' name='file' className="pdf-file" onChange={(e)=> {
							e.currentTarget.closest(".row-mapping-insurance").querySelector(".selected-pdf-file").value = e.target.files[0].name;
							//console.log(e.target.files[0]);
						}} />
                    {/* <input type='hidden' value={userid.id} name='userid'></input> */}
                    <Button type='button' variant='primary'><FaUpload size={20} /> </Button>
                    </Form.Group>
					<Form.Control type="hidden" className="selected-pdf-file" value="" />
            </InputGroup >
        </Col>
        <Col lg={2} md={12} className='d-flex justify-content-end'>
                    <Button variant='secondary' onClick={(e)=>e.currentTarget.closest(".row-mapping-insurance").remove()}><MdDelete size={20} /> Delete PDF</Button>

        </Col>
    </Row>
	)
}

export default AddMoreInsurance;