import Auth from '../../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {FaEdit} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {Button, Modal } from 'react-bootstrap';

const ManageCodingGuideLines = () => {
    const [insurancegroups, setInsuranceGroups] = useState([]);
    const [showerrmodal, setShowerrmodal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errmsg, setErrMsg] = useState("");
    const [insstatus, setInsStatus] = useState("");
    const [totalrecords, setTotalRecords] = useState("");
    
    // const windowUrl = window.location.href;
	// var segment_array = windowUrl.split( '/' );
    // var last_segment = segment_array[5];
    //console.log(last_segment);
    /**INSURANCE GROUP LIST API**/
    const windowUrl = window.location.href;
	var res = windowUrl.split("/");
	var pos = res.indexOf('manage-coding-guidelines');
	var last_segment = res[pos+1];
    console.log(last_segment);
    useEffect(() => {
        setLoading(true);
        Auth.get('/insurance-group-wise/'+last_segment).then((response) => {
            console.log('moumita', response.data);
            
            //console.log(response);
            setLoading(false);
            //console.log(response.data);
            if(response.data.status == '0' || response.data.status === 0){
                setShowerrmodal(true);
                setErrMsg(response.data.msg);
                console.log(response.data.msg);
            } else{
                setShowerrmodal(false);
            }
            setInsuranceGroups(response.data?.groupWiseData);
            setInsStatus(response.data?.status);
            setTotalRecords(response.data?.groupWiseData.length);
        }).catch(error => {
                if(error.code === 'ERR_BAD_REQUEST'){
                 console.log("error");
                 //alert(error.message);
                 localStorage.removeItem('data');
                 localStorage.removeItem("token");
               window.location.pathname = '/'
                }
          
            });

            

    },[]);

    function handleerrmodalClose(){
		
		setShowerrmodal(false);
		setTimeout(function() {
			window.location.pathname = '/'
		}, 1000);
	}

    const ErrModal = () => {
        return (
            <>
                {/*-- Error Modal--*/}
		<Modal show={showerrmodal} onHide={handleerrmodalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errmsg}.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleerrmodalClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
            </>
        )
    }

    return (
        
        <>
            { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
           
            
           
           <div className="bg-white shadow-lg py-4 px-4 rounded-4">
            <div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            
                <div className="d-flex align-items-center mb-3">
                    <div className="col-auto d-block">
                        <h2 className="table-title text-dark fw-semibold fs-3">Manage Coding Guidelines</h2>
                    </div>
                    <div className="col-auto d-block me-auto ms-3 ">
                        <div className="dataTables_length"><h5 className="text-info mb-0">{totalrecords} results found</h5></div>
                    </div>
                                
                     
                </div>

                <div className='table-responsive tableFixHead'>
                <table id="datatables-column-search-select-inputs" className="table table-striped dataTable" aria-describedby="datatables-column-search-select-inputs_info">
                    <thead className="thead-dark">
                    <tr role="row">
                    <th className="sorting align-middle text-center">Ref#</th>
                    <th className="sorting align-middle">Name</th>
                    
                    <th className="align-middle text-center">Edit</th>
                  
                    </tr>
                    </thead>

                    <tbody>
                    { (insstatus==0) ?  <ErrModal /> : 
                        (insurancegroups.map((insurancegroup, i) => {
                            return(
                            
                               
                    
                    <tr datarole="row" className={`count++ % 2 ? 'odd' : 'even'`} key={i}>
                    <td className="align-middle text-center"><strong>{insurancegroup.InsurancesID}</strong></td>
                    <td className="align-middle">{insurancegroup.InsuranceName}</td>
                    
                    <td className="align-middle text-center"><Link to={"../edit-insurance-group/" + insurancegroup.InsurancesID}>
                    <FaEdit style={{'color':'#3f80ea'}} size={20} /></Link></td>
                    

                    
                    </tr>
                        )
                        }))
                    }
                    

                    </tbody>
                    </table>

                        
                   
                </div>
            </div>
           </div> 
        </>
    
    );
};

export default ManageCodingGuideLines;