import React from 'react';
import { Stack } from 'react-bootstrap';
import CodingGuideline from '../../components/CodingGuideline';


const CallCenter = () => {
    return (
        <>
        <div class="bg-white shadow-lg py-4 px-4 rounded-3 signin-form inner-form">
            <Stack direction='vertical'>
            
            <CodingGuideline />
            
            </Stack>
        </div>    
        </>
    );
};

export default CallCenter;