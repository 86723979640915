import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { AdminRoute, CallCenterRoute, GeneralRoute, SuperAdminRoute } from './routes/Routes';

function App() {

  return (
    <>
   
  
            {/* <RoutesMain to='/gandu/' /> */}
       
            
      <Routes>
        <Route path='/' element={<GeneralRoute />} />
         <Route path="superadmin/*" element={<SuperAdminRoute />} />
         <Route path="admin/*" element={<AdminRoute />} />
         <Route path='callcenter/*' element={<CallCenterRoute />} />
      </Routes>
    
     
      
    </>
  );
}

export default App;
