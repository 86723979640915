import React from 'react';
import { Container,Row,Col,ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaXTwitter} from 'react-icons/fa6';
import {TiSocialInstagram} from 'react-icons/ti';


const Footer = () => {
    return (
        <>
            <footer className='py-2 text-white' style={{background:'#00558E'}}>
                <Container fluid className='px-4'>
                    <Row>
                        <Col md={6}>
                        <ListGroup horizontal>
                            <ListGroup.Item className="p-0 bg-transparent border-0"><Link className='text-white me-3' to="https://www.facebook.com" target='_blank'><FaFacebookSquare size={20} /></Link></ListGroup.Item>
                            <ListGroup.Item className="p-0 bg-transparent border-0"><Link className='text-white me-3' to="https://twitter.com/" target='_blank'><FaXTwitter size={20} /></Link></ListGroup.Item>
                            <ListGroup.Item className="p-0 bg-transparent border-0"><Link className='text-white me-3' to="https://www.instagram.com/" target='_blank'><TiSocialInstagram size={20} /></Link></ListGroup.Item>
                        </ListGroup>
                        </Col>
                        <Col md={6} className='text-end'>
                            <small className='text-white'>2022 Copyright Protected. All Rights Reserved.</small>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;