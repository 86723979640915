import React, {useState, useEffect} from 'react';
import Auth from '../utility/AuthConfig';
import {Button,Form,InputGroup,Stack,Row,Col,Modal,Alert} from 'react-bootstrap';
import {FaDAndDBeyond, FaTelegramPlane,FaUpload} from 'react-icons/fa';
import{BsShieldFillPlus} from 'react-icons/bs';
import {MdEditSquare} from 'react-icons/md';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const editorConfiguration = {
  removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
};

const EditInsuranceGroup = () => {

  

    const [showfilemodal, setShowFileModal] = useState(false);
    const [insdatatypes, setInsDatatypes] = useState([]);
    const [drpdwnValues, setdrpdwnValues] = useState([]);
    const [dataEditor, setdataEditor] = useState("");
    const [dataEditorID, setdataEditorID] = useState("");
    const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);
    const [excelData, setExcelData] = useState(null);
    
    
    const [successmsg, setSuccessMsg] = useState(false);
    const [successmsgshow, setSuccessMsgShow] = useState("");
    const [loading, setLoading] = useState(false);

    

    // const windowUrl = window.location.href;
	// var segment_array = windowUrl.split( '/' );
    // var last_segment = segment_array[5];
    // console.log(last_segment);

    const windowUrl = window.location.href;
	var res = windowUrl.split("/");
	var pos = res.indexOf('edit-insurance-group');
	var last_segment = res[pos+1];
	
	useEffect(() =>{
		setLoading(true);
		Auth.get('/insurance-edit-group-wise/'+last_segment).then((response) => {
            setInsDatatypes(response.data.fields);
            console.log(response.data.insurancedetails);
            
			//console.log(response.data.fields);
			
			setLoading(false); 
		}).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
    
	},[]);

    const handlechangeDrpdwn = (e) =>{
		const index = drpdwnValues.map(e => e.GoldenRulesCategoryID).indexOf(e.target.id);
		if (index > -1) { 
		  drpdwnValues.splice(index, 1);
		}
		document.getElementById(e.target.id).setAttribute("data-drpval", e.target.value);
		//console.log(index);
		/* drpdwnValues.push({
			"GoldenRulesCategoryID":e.target.id,"Contents":e.target.value
		}) */
	}

    const handlechangeEditor = ((e,editor)=>{
		const data = editor.getData();
		setdataEditor(data);
		document.getElementById(dataEditorID).getElementsByClassName("editorval")[0].value = data;
		//document.getElementById(dataEditorID).getElementsByClassName("editorval")[0].setAttribute("value", data);
	});
	
	const EditInsGroupSubmit = (e) => {
        const u_id = JSON.parse(localStorage.getItem('data'))
		let elems = document.querySelectorAll(".editorval");
		for (let i = 0; i < elems.length; i++) {
			let dataval = elems[i].getAttribute('data-val');
			if(elems[i].value == ''){
				drpdwnValues.push({
					"GoldenRulesCategoryID":elems[i].id,"Contents":dataval
				})
			}else{
				drpdwnValues.push({
					"GoldenRulesCategoryID":elems[i].id,"Contents":elems[i].value
				})
			}
		}
		let elemsdrp = document.querySelectorAll(".drpval");
		console.log(elemsdrp.length);
		for (let i = 0; i < elemsdrp.length; i++) {
			let dataval = elemsdrp[i].getAttribute('data-drpval');
			
			
				drpdwnValues.push({
					"GoldenRulesCategoryID":elemsdrp[i].id,"Contents":dataval
				})
			
		}
		//console.log(drpdwnValues);
		const Data = {
				InsurancesID:last_segment,
				CreatedbyID:u_id.id,
				insurancejson:drpdwnValues
			};
			Auth.post('/set-golden-rules-contents', Data).then((response) => {  
				console.log(response.data);
        setSuccessMsg(true);
        setSuccessMsgShow(response.data.msg);
        setTimeout(function(){
          window.location.reload();
       }, 1000);
			}) 

        
    }

    const handleFile=(e)=>{
        let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'];
        let selectedFile = e.target.files[0];
        if(selectedFile&&fileTypes.includes(selectedFile.type)){
          // if(selectedFile&&fileTypes.includes(selectedFile.type)){
          //   setTypeError(null);
          //   let reader = new FileReader();
          //   reader.readAsArrayBuffer(selectedFile);
          //   reader.onload=(e)=>{
          //     setExcelFile(e.target.result);
          //   }
          const fd = new FormData();
          fd.append('uploaded_file', selectedFile);
          Auth.defaults.headers.post['Content-Type'] = "multipart/form-data";
          Auth.post('/convert-xlsx-html', fd).then((response) => {
            //setFileUpload(response.data);
            console.log(response);
            document.getElementById("table_show").innerHTML = response.data.converted;
            setLoading(false); 
      });
          }
          else{
            setTypeError('Please select only excel file types');
            setExcelFile(null);
          }
        // }
        // else{
        //   console.log('Please select your file');
        // }
      }

      // submit file event
  // const handleFileSubmit=(e)=>{
  //   e.preventDefault();
  //   if(excelFile!==null){
  //     const workbook = XLSX.read(excelFile,{type: 'buffer'});
  //     const worksheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[worksheetName];
  //     const data = XLSX.utils.sheet_to_json(worksheet);
  //     setExcelData(data.slice(0,10));
  //   }
  // }

//   const copyToClipboard = () => {
//     let copyText = textAreaRef.current.value;
//     let isCopy = copy(copyText);
//     if (isCopy) {
//       console.log(textAreaRef);
//     }
//   };

function copyTable(){
  window.getSelection().removeAllRanges();
  var urlField = document.getElementById('table_show');
  var range = document.createRange(); 
  range.selectNode(urlField);
  window.getSelection().addRange(range);
  document.execCommand('copy');
  setShowFileModal(false);
}
	
	function handlefilemodalClose(){
		setShowFileModal(false);
	}

    function handleShowModal(){
        setShowFileModal(true);
    }

    
    
  
	
    return (
        <>
		{ loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }

    {successmsg ? <Alert variant="success">{successmsgshow}</Alert> : ""}        
            
    <Stack>
                
<Form className="signin-form inner-form" id="adduser-form" noValidate>
    <div className="mb-3">
        {
            insdatatypes.map((insdatatype,i) => {
                
                return (


                <div key={i}>

                    <div>

                        {(insdatatype.EnabelFromExcelSheet === 1 || insdatatype.EnabelFromExcelSheet === '1') &&
                        
                        <Col md={12} className='d-flex justify-content-end'>
                            <Button variant="secondary" onClick={handleShowModal} className='mb-3'>Convert XLS To HTML</Button>
                            <Modal show={showfilemodal} onHide={handlefilemodalClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">Upload File</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <form className="form-group custom-form">
                                <div className="file_upload mb-4">
                                <Form.Label className='top-0 px-0 mb-3' style={{'color':'#1886EB'}}><MdEditSquare size={22} /> {insdatatype.Name}</Form.Label>
                                <Form.Group controlId="formFile" className="mb-1 d-flex">
                                <Form.Control type='file' name='file' required onChange={handleFile} />
                                <input type='hidden' value={insdatatype.id} name='userid'></input>
                                <Button type='submit' variant='primary'><FaUpload size={20} /> </Button>
                                
                                
                                </Form.Group>
                                {typeError&&(
                                    <div className="alert alert-danger d-inline-block mt-4" role="alert">{typeError}</div>
                                )}
                                </div>
                            </form>
                                    {/* view data */}
                                  <div className="viewer">
                                      <div className="table-responsive" >
                                        <div id="table_show"></div>
                                      </div>
                                  </div>

                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant='primary'id="copy_btn" className='me-3' onClick={copyTable}>Copy</Button>
                            <Button variant="secondary" onClick={handlefilemodalClose}>Close</Button>
                            
                            </Modal.Footer>
                        </Modal>
                            
                        </Col>
                        }

                    </div>
                    
                    <div>{
                    (insdatatype.Datatype === 5 || insdatatype.Datatype === '5') && <Row className='align-items-center'>
                    <Col md={12}>
                        <InputGroup className="mb-4 d-block mx-0">
                            <Form.Label className='top-0 px-0 mb-3'><MdEditSquare size={22} /> {insdatatype.Name}</Form.Label>
                            <div id={insdatatype.ID} style={{'margin':'0px'}}>
							
							<input type="hidden" className="editorval" id={insdatatype.ID} data-val={insdatatype.Datatype ? insdatatype.Contents : null } />
                            <CKEditor 
                                    editor={ ClassicEditor }
                                    config={ editorConfiguration }
                                    data={insdatatype.Datatype ? insdatatype.Contents : null }
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ handlechangeEditor}
                                    onFocus={ ( event, editor ) => {
                                        setdataEditorID(insdatatype.ID );
                                        
                                    }}
                                    
                                    
                                />
                            </div>
                            
                        </InputGroup >
                    </Col>
                     
                </Row>
                    }
                    {
                        (insdatatype.Datatype === 6 || insdatatype.Datatype === '6') && <Row className='align-items-center'>
                        <Col md={12}>
                        <InputGroup className="mb-4 d-block mx-0 row-mapping-insurance">
                            <Form.Label className='top-0 px-0 mb-3'><MdEditSquare size={22} /> {insdatatype.Name}</Form.Label>
                            <div id={insdatatype.ID} style={{'margin':'0px'}}>
							
							<input type="hidden" className="editorval" id={insdatatype.ID} data-val={insdatatype.Datatype ? insdatatype.Contents : null } />
                            <CKEditor 
                                    editor={ ClassicEditor }
                                    data={insdatatype.Datatype ? insdatatype.Contents : null }
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ handlechangeEditor}
                                    onFocus={ ( event, editor ) => {
                                        setdataEditorID(insdatatype.ID );
                                        
                                    }}
                                    
                                    config={{
                                        removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                                    }}
                                    
                                />
                            </div>    
                        </InputGroup >
                        </Col>
                         
                    </Row>
                    }
                    {
                        (insdatatype.Datatype === 7 || insdatatype.Datatype === '7') && <Row className='align-items-center'>
                        <Col lg={4} md={12}>
                            <InputGroup className="mb-4 mx-0">
                                <Form.Label><BsShieldFillPlus size={22} /> {insdatatype.Name}</Form.Label>
                                <Form.Select 
                                    required
                                    id={insdatatype.ID} onChange={handlechangeDrpdwn} 
                                    //onChange={(e) => setCatGroup(e.target.value)}
                                    aria-label="Default select example" className="drpval" data-drpval={insdatatype.Datatype ? insdatatype.Contents : null } data-drpid={insdatatype.ID} >
                                    
                                  <option value="">Please select</option>
									<option value="Yes" selected={insdatatype.Datatype && insdatatype.Contents=='Yes' ? "selected" : null }>Yes</option>
									<option value="No" selected={insdatatype.Datatype && insdatatype.Contents=='No' ? "selected" : null }>No</option>
                                    
                                </Form.Select>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a Category.
                                    </Form.Control.Feedback>
                            </InputGroup >
                        </Col>
                         
                    </Row>
                    }</div>
                </div>
                );
            })
        }
        
        
        
        
      
      
      
      



    </div>
      <Row className='justify-content-center'>
        <Col sm={12} className='d-flex justify-content-center'>
        <Button id='submitbtn' variant='link' className='me-2' onClick={EditInsGroupSubmit}><FaTelegramPlane className='foms-1 ms-1' size={23} /> Submit</Button>
        
        
        </Col>
    </Row>

   
  </Form>  

  
  </Stack>
        </>
    );
};

export default EditInsuranceGroup;

