import Auth from '../../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {FaCheck, FaEdit} from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';
import {BiSolidMessageSquareAdd} from 'react-icons/bi';
import {ImCross} from 'react-icons/im';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Pagination from 'react-bootstrap/Pagination';

const AdminCodingGuidelines = () => {

    const [CGdata, setCGData] = useState([]);
    const [sortData, setSortData] = useState([]);
    const [totalrecords, setTotalRecords] = useState("");
    const [catpagination, setCatPagination] = useState("");
	const [currntpage, setCurrntPage] = useState('');
	const [statusmsg, setStatusMsg] = useState("");
	const [dispalymsg, setDisplayMsg] = useState(false);
    const [loading, setLoading] = useState(false);
	
	const userTypeVal = localStorage.getItem('data');
    const userid = JSON.parse(userTypeVal);
	
   /**CATEGORY LIST API**/
    useEffect(() => {
        setLoading(true);
        Auth.get('/getcategorylist/1').then((response) => {
            setCGData(response.data.categories);
            setTotalRecords(response.data.resultdetails[0].total_records);
            setCatPagination(response.data.resultdetails[0].total_no_of_pages);
            setCurrntPage(response.data.resultdetails[0].currentPage);
            console.log(response.data);
            setLoading(false);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });

    },[]);

	/**PAGINATION CATEGORY API**/
    const CatPaginationNum = (e) =>{
        let pageid = e.target.dataset.pageid;
        setLoading(true);
        Auth.get('/getcategorylist/' + pageid).then((response) => {
            setCGData(response.data.categories);
            setTotalRecords(response.data.resultdetails[0].total_records);
            setCatPagination(response.data.resultdetails[0].total_no_of_pages);
            setCurrntPage(response.data.resultdetails[0].currentPage)
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
        
    }

 /**PAGINATION**/ 
let items = [];
for (let number = 1; number <= catpagination; number++) {
  items.push(
    <Pagination.Item key={number} active={number == currntpage || number === currntpage} data-pageid={number} data-current={currntpage} onClick={CatPaginationNum}>
      {number}
    </Pagination.Item>,
  );
}

	/** CHANGE STATUS **/
	const changeStatus = (target,catID) => {
		
		
		const data = {
			catId: catID,
            status:(target.dataset.status==='1') ? 0 : 1,
            userid:userid.id,
    
          };
          setLoading(true);
          Auth.post('/change-category-status', data).then((response) => {    
            target.style.color = (target.dataset.status==='1') ? 'red' : '#32CD32';
		    target.innerHTML = (target.dataset.status==='1') ? 'Inactive' : 'Active';
			(target.dataset.status==='1') ? target.setAttribute('data-status',0) : target.setAttribute('data-status',1);
			setStatusMsg(response.data.msg);
            setDisplayMsg(true);
            setLoading(false);
         

        })
	}

    return (
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
           <div className="bg-white shadow-lg py-4 px-4 rounded-4">
            <div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                <div className="d-flex align-items-center mb-3">
                    <div className="col-auto d-block">
                        <h2 className="table-title text-dark fw-semibold fs-3">List of Categories</h2>
                    </div>
                    <div className="col-auto d-block me-auto ms-3 ">
                        <div className="dataTables_length"><h5 className="text-info mb-0">{totalrecords} results found</h5></div>
                    </div>
					{
                                dispalymsg ? <Alert variant="success">{statusmsg}</Alert> : ""
                               }
                    <div className="col-auto d-block ms-auto ms-3 ">
                        <div className="dataTables_length">
                        <Link to="../add-admin-user" className='btn btn-primary'><BiSolidMessageSquareAdd className='me-2' size={20} /> Add Category </Link>
                        </div>
                    </div>
                </div>

                <div className='table-responsive tableFixHead'>
                <table id="datatables-column-search-select-inputs" className="table table-striped dataTable" aria-describedby="datatables-column-search-select-inputs_info">
                    <thead className="thead-dark">
                    <tr role="row">
                    <th className="sorting align-middle text-center">Ref#</th>
                    <th className="sorting align-middle">Category Name</th>
                    <th className="sorting align-middle">Group</th>
                    <th className="sorting align-middle">Data Type</th>
                    <th className="align-middle text-center">Status</th>
                    <th className="align-middle text-center">Edit</th>
                    <th className="align-middle text-center">Delete</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        CGdata.map((CGdatain, i) => {
                            return(
                            
                               
                    
                    <tr role="row" className={`count++ % 2 ? 'odd' : 'even'`} key={i}>
                    <td className="align-middle text-center"><strong>{CGdatain.ID}</strong></td>
                    <td className="align-middle">{CGdatain.Name}</td>
                    <td className="align-middle">{CGdatain.CategoryName}</td>
                    <td className="align-middle">{CGdatain.DataTypeName}</td>
                    <td className="align-middle text-center">{CGdatain.IsActive === '1' ? <span data-status={1} style={{'color':'#32CD32','font-weight':'600','cursor':'pointer'}} onClick={(e) => changeStatus(e.target,CGdatain.ID)}>Active</span>: <span data-status={0} style={{'color':'red','font-weight':'600','cursor':'pointer'}} onClick={(e) => changeStatus(e.target,CGdatain.ID)}>Inactive</span>}</td>
                    <td className="align-middle text-center"><Link to={"../category-edit/" + CGdatain.ID}>
                    <FaEdit style={{'color':'#3f80ea'}} size={20} /></Link></td>
                    <td className="align-middle text-center"><MdDelete style={{'color':'red','pointerEvents':'none','userSelect':'none','opacity':'0.5'}} size={20} /></td>
                    </tr>
                        )
                        })
                    }
                    

                    </tbody>
                    </table>
                    <div className='mt-4'><Pagination className='justify-content-end'>{items}</Pagination></div> 
                </div>
            </div>
           </div> 
        </>
    );
};

export default AdminCodingGuidelines;