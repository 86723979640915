import axios from 'axios';
import Auth from '../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {Card, Form, Button, ListGroup} from 'react-bootstrap';
import {FaHandHoldingMedical} from 'react-icons/fa';
import { IoCodeWorkingSharp } from "react-icons/io5";
import { HiOfficeBuilding } from "react-icons/hi";
import { MdPictureAsPdf, MdDoNotDisturb} from 'react-icons/md';


const CodingGuideline = () => {

    const [CGvalues, setCGValues] = useState([]);
    const [BOvalues, setBOValues] = useState([]);
    const [INvalues, setINValues] = useState([]);
    const [insdes, setInsDes] = useState([]);
    const [buisnessdes, setBuisnesDdes] = useState([]);
    const [loading, setLoading] = useState(false);

    

 useEffect(() =>{
        setLoading(true);
        Auth.get('/getcategorylist-coading-guideline').then((response) => {
        setCGValues(response.data.categories);
        
        //console.log(response.data);

        setLoading(false);
        //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });

        setLoading(true);
        Auth.get('/getcategorylist-business-office').then((response) => {
        setBOValues(response.data.categories);
        //console.log(response.data);

        setLoading(false);
        //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });

        setLoading(true);
        Auth.get('/getinsurancelistfrontend').then((response) => {
        setINValues(response.data.insurances);
        //console.log(response.data);

        setLoading(false);
        //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });


 },[])



 const onchangeHandler = (option, str) =>{

    if(str == 'codingguideline'){
        setLoading(true);
        Auth.get('/frontendcategoryinsurance/'+option+'/0').then((response) => {
            //setDescriptions(response.data.categories);
            console.log(response.data);
           
            setLoading(false);
            //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
        // document.getElementById('codingguideline').style.display = "block";
        // document.getElementById('buisnessoffice').style.display = "none";
        // document.getElementById('insurance').style.display = "none";
    }else if(str == 'buisnessoffice'){
        setLoading(true);
        Auth.get('/frontendcategoryinsurance/'+option+'/0').then((response) => {
            setBuisnesDdes(response.data.categorydetails);
            console.log(response.data.categorydetails);
           
            setLoading(false);
            //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
        // document.getElementById('buisnessoffice').style.display = "block";
        // document.getElementById('codingguideline').style.display = "none";
        // document.getElementById('insurance').style.display = "none";
    }else if(str == 'insurance'){
        setLoading(true);
        Auth.get('/frontendcategoryinsurance/'+'0/'+option).then((response) => {
            setInsDes(response.data.insurancedetails);
            console.log(response.data);
           
            setLoading(false);
            //console.log(response.data);
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
        // document.getElementById('insurance').style.display = "block";
        // document.getElementById('codingguideline').style.display = "none";
        // document.getElementById('buisnessoffice').style.display = "none";
    }
    //alert(option);
    //alert(str);
}

const downloadpdf = (file)=>{
    Auth.get('/files/'+file,{responseType: 'blob'}).then((response)=>{
       const url = window.URL.createObjectURL(response.data);
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', file);
       document.body.appendChild(link);
       link.click(); 
       document.body.removeChild(link);
    }).catch(error => {
        if(error.code === 'ERR_BAD_REQUEST'){
         console.log('error');
         localStorage.removeItem('data');
         localStorage.removeItem("token");
       window.location.pathname = '/'
        }
  
    });
}

    return (
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
        <div className='d-inline-block w-25'>
            <Form.Label className='mb-0'>Select Coding Guideline</Form.Label>
                <div className='mb-4 custom-select input-group'>
                <Form.Label><IoCodeWorkingSharp size={22} /> Coding Guideline</Form.Label>
                    <Form.Select  onChange={(e) => onchangeHandler(e.target.value, 'codingguideline')} >
                    <option value="">Please choose Option</option>
                    {
                        CGvalues.map((CGvalue,i) => {
                            return <option key={i} value={CGvalue.ID}>{CGvalue.Name}</option>
                        })
                    }
                </Form.Select>
                </div>
               
            
       
               
        </div>

            <Card className='shadow-lg callcenter' id="codingguideline">
                
                     {/* <Card.Header as="h4" className='text-white py-2' style={{'background':'#7987A1'}}></Card.Header> */}
                     

                     
            </Card> 

            <div className='d-inline-block w-25'>
            <Form.Label className='mb-0'>Select Business Office Directory</Form.Label>
                <div className='mb-4 custom-select input-group'>
                <Form.Label><HiOfficeBuilding  size={22} /> Business Office Directory</Form.Label>
                    <Form.Select onChange={(e) => onchangeHandler(e.target.value, 'buisnessoffice')}>
                    <option value="">Please choose Option</option>
                    {
                        BOvalues.map((BOvalue,i) => {
                            return <option key={i} value={BOvalue.ID}>{BOvalue.Name}</option>
                        })
                    }
                </Form.Select>
                </div>
               
            
       
               
        </div>

        <Card className='shadow-lg callcenter' id="buisnessoffice">
                    {
                        buisnessdes.map((buisnessinfo, i) => {
                            return(
                            <div key={i}>
                            <Card.Header as="h4" className='text-white py-2' style={{'background':'#7987A1'}}>{buisnessinfo.InsuranceName}</Card.Header>
                            <div className='p-3' dangerouslySetInnerHTML={{ __html: buisnessinfo.Contents }}></div>
                            </div>
                            );
                        })
                    }

                     
                     

                     
            </Card>

            <div className='d-inline-block w-25'>
            <Form.Label className='mb-0'>Select an Insurance</Form.Label>
                <div className='mb-4 custom-select input-group'>
                    <Form.Label><FaHandHoldingMedical size={22} />Insurance</Form.Label>
                    <Form.Select onChange={(e) => onchangeHandler(e.target.value, 'insurance')}>
                    <option value="">Please choose Option</option>
                    {
                        INvalues.map((INvalue,i) => {
                            return <option key={i} value={INvalue.InsurancesID}>{INvalue.InsuranceName}</option>
                        })
                    }
                </Form.Select>
                </div>
               
            
       
               
        </div>

        <Card className='shadow-lg callcenter' id="insurance">
                     {/* <Card.Header as="h4" className='text-white py-2' style={{'background':'#7987A1'}}></Card.Header> */}
                     

                     <div className='table-responsive tableFixHead'>
                
                    
                     <ListGroup as="ol" numbered>
                    {
                        insdes.map((insinfo, i) => {
                            return(
                                <ListGroup.Item variant="primary"
                                as="li"
                                className="d-flex align-items-center"><strong className='w-75 ms-2'>{insinfo.CategoryName}</strong>
                        <span className='w-25 text-end'>{
					    insinfo.PDFUrl!="" ? <Button type='button' className='d-inline-block' variant='primary' size="sm" onClick={()=>downloadpdf(insinfo.PDFUrl)}><MdPictureAsPdf size={15} className='me-2' />Download</Button> : <Button type='button' className='d-inline-block' size="sm" variant='secondary'><MdDoNotDisturb size={15} className='me-2' />No Data</Button>
				        }  </span>  
                        </ListGroup.Item>
                        )
                    })
                }
                    </ListGroup>
                    
                    </div>
            </Card> 
     
        </>
    );
};

export default CodingGuideline;