import React from 'react';
import { Row,Col,Button,ButtonGroup } from 'react-bootstrap';
import {HiOutlineLogout} from 'react-icons/hi';
import { Outlet } from 'react-router-dom';
import { FaStepBackward } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
//import { useLocation } from 'react-router-dom';

const MainBody = ({onLogOut, expand}) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <div className={`main ${expand ? "sidebarClose" : ""}`}>
            <main className="content">
            <div className='py-4'>
                <Row className='align-items-center'>
                    <Col md={4}><h1>Golden Rules</h1></Col>
                    <Col md={8} className='d-flex justify-content-end'>
                    <ButtonGroup aria-label="Basic example">
                        {/* <Button variant="primary" className='me-3'><TbReceipt size={20} /> Contract</Button> */}
                        {location.pathname !=="/dashboard" &&
                            <Button variant='primary' onClick={() => navigate(-1)} className='me-2'><FaStepBackward size={17} /> Back</Button>
                        }
                        <Button variant="secondary"  onClick={onLogOut}><HiOutlineLogout size={20} /> Logout</Button>
                        
                    </ButtonGroup>
                    </Col>
                </Row>
            </div>
                
                    <Outlet />
                
            </main>
            </div>
        </>
    );
};

export default MainBody;