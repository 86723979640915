import React, {useState, useEffect} from 'react';
import {Button,Form,InputGroup, Stack,Row, Col } from 'react-bootstrap';
import {FaTelegramPlane,FaHandHoldingMedical} from 'react-icons/fa';
import {MdDateRange} from 'react-icons/md';
import axios from 'axios';
import DatePicker from "react-datepicker";  // need to install - npm install react-datepicker --save 
import "react-datepicker/dist/react-datepicker.css";  
 
const InsuranceEditForm = () => {
	const windowUrl = window.location.href;
	var segment_array = windowUrl.split( '/' );
	var last_segment = segment_array.pop();
	
	
	
	
	const [date, setDate] = useState(new Date());
	const [insID, setInsId] = useState(last_segment);
	const [InsuranceName, setInsuranceName] = useState("");
	const [active, setActive] = useState("");
	const [loading, setLoading] = useState(false);
	//const [checked, setChecked] = useState("");
	const [sucmsg, setsucmsg] = useState("");
	const token = JSON.parse(localStorage.getItem('token'));
	
	const userTypeVal = localStorage.getItem('data');
    const userid = JSON.parse(userTypeVal);
	const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            'Accept' : 'application/json',
            'Content-Type': 'application/json' 
        }
    };
	useEffect(() =>{
		setLoading(true);
		axios.get('http://107.180.103.25:420/entsp-goldenrule/public/api/update-insurance/'+insID, {headers:{
		'Authorization':'Bearer ' + token
		}}).then((response) => {
			//console.log(response.data);
			setInsuranceName(response.data.details.InsuranceName);
			setDate(new Date(Date.parse(response.data.details.DeactivateDate)));
			setActive(response.data.details.IsGoldenRule);
			setLoading(false); 
		}).catch(error => {
			if(error.code === 'ERR_BAD_REQUEST'){
			 console.log('error');
			 localStorage.removeItem('data');
                 localStorage.removeItem("token");
		   window.location.pathname = '/'
			}
	  
		});
	},[]);
	
	function handleChangeDate(e) {  
		setDate(e);
	}
		
	function handlecheckbox(e){
		let value = e.currentTarget.value;
		if(value == 0){
			setActive(1);
		}else if(value == 1){
			setActive(0);
		}
	} 
	
	function getInsurancename(name){
		setInsuranceName(name);
	}
	
	
	function editInsurance(){
		//console.log(insID+'-'+InsuranceName+'-'+date+'-'+active);
		const editdate = {
			InsurancesID:insID,
			insurancesname:InsuranceName,
			deactivatedate:date,
			goldenrule:active,
			userid:userid.id
		};
		setLoading(true);
		axios.post('http://107.180.103.25:420/entsp-goldenrule/public/api/update-insurance/'+insID, editdate, config).then((response) => {
			console.log(response.data);
			setLoading(false); 
			setsucmsg(response.data.msg);
			setTimeout(function() {
				window.location.replace('../insurance-lists');
			}, 2000);
		}).catch((error) => {
		console.log(error);
		})
	}
	
    return (
        <>
		{ loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
            <Stack>
			{
				(sucmsg) ? (
					<div role="alert" className="fade alert alert-success show">{sucmsg}</div>
				) : (
					null
				)
			}
<Form className="signin-form inner-form" id="adduser-form" noValidate>
    <div className="mb-3">
        <Row className='align-items-center'>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4">
                    <Form.Label><FaHandHoldingMedical size={22} /> Insurance Name</Form.Label>
                    <Form.Control id="catname"
                    required
                    type='text'
                    
                    autoComplete='off'
					onChange={e => getInsurancename(e.target.value)}
                    value={InsuranceName}
                    placeholder="Please Enter Category Name"/>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Please choose a Category.
                        </Form.Control.Feedback>
                </InputGroup >
            </Col>
             <Col lg={4} md={12}>
			 
				
				 <InputGroup className="mb-4" style={{'position':'inherit'}}>
				 <Form.Label><MdDateRange size={22} />Select Deactivate Date</Form.Label>
				 <DatePicker  
              selected={ date }  
              onChange={ handleChangeDate }  
              name="startDate"  
			  minDate={new Date()}  
			  className="form-control"
              dateFormat="yyyy/MM/dd"  
          />  
		  </InputGroup >
			</Col>
			<Col lg={4} md={12}>
				<fieldset>
				<Form.Group  className="d-flex align-items-center  custom-check">
				<Form.Label className='me-3'>Show In GoldenRule</Form.Label>
					<div className="form-check">
					
					{
					(active=="1") ? (
						<label class="checkbox">
							<Form.Check
                            inline
                            name="catstatus"
                            type="checkbox"
                            value={active} id="flexCheckDefault"
							checked="checked"
                            onChange={handlecheckbox}
                             />
							 </label>
							
						) : (
							<label class="checkbox">
							<Form.Check
                            inline
                            name="catstatus"
                            type="checkbox"
                            value={active} id="flexCheckDefault"
                            onChange={handlecheckbox}
                             />
							 </label>
							)
				}

					</div>
					</Form.Group>
				</fieldset>
            </Col>
        </Row>
        
      
      
      
      



    </div>
      <Row className='justify-content-center'>
        <Col sm={3} className='d-flex '>
        <Button id='submitbtn' variant='link' className='me-2' onClick={editInsurance}><FaTelegramPlane className='foms-1 ms-1' size={23} /> Submit</Button>
        
        
        </Col>
    </Row>

   
  </Form>  

  
  </Stack>
        </>
    );
};

export default InsuranceEditForm;

