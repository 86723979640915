import Auth from '../utility/AuthConfig';
import React, { useEffect, useState } from 'react';
import {Button,Form,InputGroup, Stack,Row, Col } from 'react-bootstrap';
import {GiCancel} from 'react-icons/gi';
import {FaTelegramPlane, FaUpload, FaHandHoldingMedical} from 'react-icons/fa';
import {BiSolidUserDetail} from 'react-icons/bi';
import {FaUsersLine} from 'react-icons/fa6';
import {RiParentFill} from 'react-icons/ri';
import {MdDelete, MdPictureAsPdf} from 'react-icons/md';
import {HiDocumentAdd} from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import IMAGES from '../assets/images/IMAGES';
import Alert from 'react-bootstrap/Alert';
import AddMoreInsurance from "./AddMoreInsurance";
 
const CategoryEditForm = () => {
	const uParam = useParams();
    const [groups, setGroups] = useState([]);
    const [types, setTypes] = useState([]);
    const [cats, setCats] = useState([]);
    const [insurancelst, setInsuranceLst] = useState([]);
    const [addinsurance, setAddInsurance] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [addinsurancelst, setAddInsuranceLst]= useState([]);

    const token = JSON.parse(localStorage.getItem('token'));
	
	const [catname, setCatName] = useState("");
    const [catgroup, setCatGroup] = useState("");
    const [parentcat, setParentCat] = useState("");
    const [datatype, setDataType] = useState("");
    const [actinact, setActInact] = useState("");
    const [endisable, setEnDisable] = useState("");
    const [selectinsurance, setSelectInsurancre] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [uploadedPdfs, setUploadedPdfs] = useState([]);
    const [mappingData, setMappingData] = useState([]);
	const [newRowData,setNewRowData] = useState([]);

    const userTypeVal = localStorage.getItem('data');
    const userid = JSON.parse(userTypeVal);
	
	
    
    useEffect(() =>{
         /**GET CATEGORY GROUP**/
         setLoading(true);
         Auth.get('/getcategorygroup').then((response) => {
                setGroups(response.data.groups);
                //console.log(response.data);
                setLoading(false); 
            }).catch(error => {
                if(error.code === 'ERR_BAD_REQUEST'){
                 console.log('error');
                 localStorage.removeItem('data');
                 localStorage.removeItem("token");
               window.location.pathname = '/'
                }
          
            });
     
     /**GET DATA TYPE**/
     setLoading(true);
     Auth.get('/getdatatype').then((response) => {
            setTypes(response.data.types);
            //console.log(response.data);
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
         /**GET CATEGORY LIST**/
         setLoading(true);
         Auth.get('/getallcategories').then((response) => {
            setCats(response.data.categories);
            //console.log(response.data);
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
        /**LIST INSURANCE API**/
        setLoading(true);
        Auth.get('/getallinsurancelist').then((response) => {
            setInsuranceLst(response.data.insurances);
            //console.log(response.data);
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });

        /* GET CATEGORY BY ID */
        setLoading(true);
		Auth.get('/getcategory/'+uParam.id).then((response)=>{
			console.log("Category Details:",response.data.category)
			//setCatDetails(response.data.category);
			
			setTimeout(()=>{
				setCatName(response?.data?.category?.Name);
				document.getElementById("catgroup").value=response.data.category.CategoryGroupID;
				setCatGroup(response.data.category.CategoryGroupID);
				document.getElementById("parentcat").value = response?.data?.category?.ParentID!="0" ? response.data.category.ParentID : '';
				if(response?.data?.category?.Datatype !=null) {document.getElementById(response?.data?.category?.Datatype).click()};
				response?.data?.category?.IsActive == "1" ? document.getElementById("active").click() : document.getElementById("inactive").click()
				response?.data?.category?.EnabelFromExcelSheet == "1" ? document.getElementById("enable").click() : document.getElementById("disable").click();
				setMappingData(response?.data?.mappingDataSet);
				
				setLoading(false); 			
			},1000);
		}).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
                 localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });

 },[]);

	
    

 const EditCategorySubmit = (e) => {
    e.preventDefault();
	
	
	const btnPointer = document.querySelector('#submitbtn');

    if(document.getElementById('catname').value === "" || document.getElementById('catgroup').value === ""){
      setError("Please add category name & choose category field & data type");
      btnPointer.innerHTML = 'Submit <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
          document.querySelector('#login-btn-img').setAttribute('class','ms-1');
          btnPointer.removeAttribute('disabled');
          return false;
      
    } else {
		const formData = new FormData();
		
	   //console.log(uploadedPdfs);
	   formData.append("category_id",uParam.id);
	   formData.append("category_name",catname);
	   formData.append("parent_category_id",parentcat);
	   formData.append("category_group_id",catgroup);
	   formData.append("data_type",datatype);
	   formData.append("is_cat_edit_active",actinact);
	   formData.append("enable_from_excel_sheet",endisable);
	   formData.append("created_by",userid.id);
	   
	   
	   let elems = document.getElementsByClassName("row-mapping-insurance")
	   Array.from(elems).forEach(function (element,idx) {
		   //console.log(element.innerHTML);
		  console.log(element.querySelector(".select-insurance").value);
		  console.log(element.querySelector(".pdf-file").files[0]);
		  formData.append("insuranceId[" + idx + "]",element.querySelector(".select-insurance").value);
		  if(element.querySelector(".pdf-file").files.length == 0){
			if(element.querySelector(".selected-pdf-file").value==""){
				formData.append("uploadedpdffiles[" + idx + "]",'');
				formData.append("selectedpdf[" + idx + "]",'');
			}
			else{
				formData.append("uploadedpdffiles[" + idx + "]",'');
				formData.append("selectedpdf[" + idx + "]",element.querySelector(".selected-pdf-file").value);
			}
		  }
		  else{
			  formData.append("uploadedpdffiles[" + idx + "]",element.querySelector(".pdf-file").files[0]);
			  formData.append("selectedpdf[" + idx + "]",element.querySelector(".selected-pdf-file").value);
		  }
		  
		  //insurancePdf.push({"insuranceId":element.querySelector(".select-insurance").value,"uploadedPDF":element.querySelector(".pdf-file").files[0]});
		});
		//formData.append("uploadedpdffiles",JSON.stringify(insurancePdf));
	   /* uploadedPdfs.forEach((pdf)=>{
		   formData.append("uploadedpdffiles[]",pdf);
	   }) */
	   
	   /* 
		const addCategoryData = {
			category_name:catname,
			parent_category_id:parentcat,
			category_group_id:catgroup,
			data_type:datatype,
			is_cat_edit_active:actinact,
			created_by:userid.id

		};


		   */
		  //btnPointer.innerHTML = 'Please wait..';
		  btnPointer.setAttribute('disabled', true);
		Auth.defaults.headers.post['Content-Type'] = "multipart/form-data";  
		Auth.post("/update-category",formData).then((response)=>{
			console.log(response);
			
        btnPointer.removeAttribute('disabled');
		setSuccess(response.data.msg);
       setTimeout(function() {
            window.location.replace('../coding-guidelines');
        }, 2000); 
		}).catch((error) => {
		  setError(error.response.data.msg);
		  console.log(error.response.data.msg);
		  //btnPointer.innerHTML = 'Submit <img id="login-btn-img" src="'+IMAGES.signIn + '" alt="Login btn"/>';
			//document.querySelector('#login-btn-img').setAttribute('class','ms-1');
			btnPointer.removeAttribute('disabled');
		}); 
    }

    
    

  }
  
	const downloadpdf = (file)=>{
		Auth.get('/files/'+file,{responseType: 'blob'}).then((response)=>{
		   const url = window.URL.createObjectURL(response.data);
		   const link = document.createElement('a');
		   link.href = url;
		   link.setAttribute('download', file);
		   document.body.appendChild(link);
		   link.click(); 
		   document.body.removeChild(link);
		}).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.clear();
           window.location.pathname = '/'
            }
      
        });
	}
    
    const addInsuranceRow = () => {
		setNewRowData(c => [...c,<AddMoreInsurance changeHandler={(e)=> setSelectInsurancre(e.target.value)}/>])
	}
	
    return (
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
             <Stack>

             {success ? <Alert variant="success">{success}</Alert> : ""}

             {error ? <Alert variant="danger">{error}</Alert> : ""}

<Form className="signin-form inner-form" id="editcategory-form" noValidate onSubmit={EditCategorySubmit} >
    <div className="mb-3">
    <Row>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4">
                    <Form.Label><BiSolidUserDetail size={22} /> Category Name</Form.Label>
                    <Form.Control id="catname"
                    required
                    type='text'
                    value={catname}
                    autoComplete='off'
                    onChange={(e) => setCatName(e.target.value)}
                    
                    placeholder="Please Enter Category Name"/>
                    {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Please choose a Category.
					</Form.Control.Feedback>*/}
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4 custom-select">
                    <Form.Label><FaUsersLine size={22} /> Category Group</Form.Label>
                    <Form.Select
                    required
                    id="catgroup"
                    onChange={(e) => setCatGroup(e.target.value)}
                    aria-label="Default select example">
                        <option value="">Open this select menu</option>
                        {
                            groups.map((group,i) => {
                                return catgroup === group.value ? <option key={i} value={group.Value} selected="selected">{group.Name}</option> : <option key={i} value={group.Value}>{group.Name}</option>
								
                            })
                        }

                    
                    
                    </Form.Select>
                    
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
                <InputGroup className="mb-4 custom-select">
                    <Form.Label><RiParentFill size={22} /> Parent Category</Form.Label>
                    <Form.Select
                    id="parentcat"
                    onChange={(e) => setParentCat(e.target.value)} 
                    aria-label="Default select example">
                    <option value="">Open this select menu</option>
                    {
                            cats.map((cat,i) => {
                                return <option key={i} value={cat.ID}>{cat.Name}</option>
                            })
                        }
                    </Form.Select>
                    
                </InputGroup >
            </Col>
        </Row>
        <Row>
            <Col md={12}>
            <fieldset>
                <Form.Group  className="mb-3 d-flex align-items-center customradio ">
                
                <Form.Label className='me-3'>Data Type</Form.Label>

                        {
                            types.map((type,i) => {
                                return <Form.Check
                                key={i}
                                inline
                                required
                                label={type.Name}
                                name="datatype"
                                type="radio"
                                id={type.Value}
                                value={type.Value}
                                checked={datatype === type.Value}
                                onChange={(e) => setDataType(e.target.value)}
                            />
                            })
                        }

                    
                </Form.Group>
      </fieldset>
            </Col>
            <Col md={12}>
                <fieldset>
                    <Form.Group className="mb-3 d-flex align-items-center customradio">
                    
                    <Form.Label className='me-3'>Active / Inactive</Form.Label>

                       
                    <Form.Check
                            inline
                            label="Yes"
                            name="catstatus"
                            type="radio"
                            id="active"
                            value="1"
                            
                            onChange={(e) => setActInact(e.target.value)}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="catstatus"
                            type="radio"
                            id="inactive"
                            value="0"
                            onChange={(e) => setActInact(e.target.value)}
                        />
                        
                    </Form.Group>
                   
            </fieldset>
            </Col>
            <Col md={12}>
                <fieldset>
                    <Form.Group className="mb-3 d-flex align-items-center customradio">
                    <Form.Label className='me-3'>Enable to add data from excel sheet</Form.Label>
                    <Form.Check
                            inline
                            label="Yes"
                            name="excelsh"
                            type="radio"
                            id="enable"
                            value="1"
                            onChange={(e) => setEnDisable(e.target.value)}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="excelsh"
                            type="radio"
                            id="disable"
                            value="0"
                            onChange={(e) => setEnDisable(e.target.value)}
                        />
                       
                    </Form.Group>
        </fieldset>
            </Col>
        </Row>

        {
			mappingData?.length > 0 ? 
				mappingData.map((ins,idx)=>{
					return(
						<Row className='align-items-center row-mapping-insurance'>
            <Col lg={4} md={12}>
            <InputGroup className="mb-4 custom-select">
                    <Form.Label><FaHandHoldingMedical size={22} /> Select An Insurance</Form.Label>
                    <Form.Select
                    id="selectinsurance"
					className="select-insurance"
                    onChange={(e) => setSelectInsurancre(e.target.value)} 
                    aria-label="Default select example">
                    <option value="">Open this select menu</option>
                    {
                            insurancelst.map((insurance,i) => {
                                if(insurance.InsurancesID == ins.InsuranceID){
									return <option key={i} value={insurance.InsurancesID} selected="selected">{insurance.InsuranceName}</option>
								}
								else{
									return <option key={i} value={insurance.InsurancesID}>{insurance.InsuranceName}</option>
								}
                            })
                        }
                    </Form.Select>
                    
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
            <InputGroup className="mb-4 file_upload">
                    <Form.Label><MdPictureAsPdf size={22} /> Upload PDF</Form.Label>
                    <Form.Group controlId="formFile" className="mb-1 d-flex" style={{'margin-left':'1px'}}>
                        <Form.Control type='file' name='file' className="pdf-file" onChange={(e)=> {
							e.currentTarget.closest(".row-mapping-insurance").querySelector(".selected-pdf-file").value = e.target.files[0].name;
						}}/>
                        {/* <input type='hidden' value={userid.id} name='userid'></input> */}
                        <Button type='button' variant='primary'><FaUpload size={20} /> </Button>
                        </Form.Group>
						{
							ins.PDFUrl!="" ? <Form.Control type="hidden" className="selected-pdf-file" value={ins.PDFUrl} /> : <Form.Control type="hidden" className="selected-pdf-file" value="" />
							
						}
                    
                </InputGroup >
                </Col>
                <Col lg={4} md={12} className='d-flex justify-content-start'>
				{
					ins.PDFUrl!="" ? <Button type='button' className='me-3 pdf-link' variant='primary' onClick={()=>downloadpdf(ins.PDFUrl)}><MdPictureAsPdf size={22} className='me-2' />{ins.PDFUrl}</Button> : null
				}
           
			{
				idx>1 ? <Button variant='secondary' onClick={(e)=>e.currentTarget.closest(".row-mapping-insurance").remove()}><MdDelete size={20} /> Delete PDF</Button> : null
			}
             </Col>
            {/*<Col lg={2} md={12} className='d-flex justify-content-end'>
                        <Button variant='secondary'><MdDelete size={20} /> Delete PDF</Button>

            </Col>*/}
        </Row>
					)
				})
			:
				<>
				<Row className='align-items-center row-mapping-insurance'>
            <Col lg={4} md={12}>
            <InputGroup className="mb-4 custom-select">
                    <Form.Label><FaHandHoldingMedical size={22} /> Select An Insurance</Form.Label>
                    <Form.Select
                    id="selectinsurance"
					className="select-insurance"
                    onChange={(e) => setSelectInsurancre(e.target.value)} 
                    aria-label="Default select example">
                    <option value="">Open this select menu</option>
                    {
                            insurancelst.map((insurance,i) => {
                                return <option key={i} value={insurance.InsurancesID}>{insurance.InsuranceName}</option>
                            })
                        }
                    </Form.Select>
                    
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
            <InputGroup className="mb-4 file_upload">
                    <Form.Label><MdPictureAsPdf size={22} /> Upload PDF</Form.Label>
                    <Form.Group controlId="formFile" className="mb-1 d-flex" style={{'margin-left':'1px'}}>
                        <Form.Control type='file' name='file' className="pdf-file" onChange={(e)=> {
							e.currentTarget.closest(".row-mapping-insurance").querySelector(".selected-pdf-file").value = e.target.files[0].name;
							//setUploadedPdfs([...uploadedPdfs,e.target.files[0]])
						}}/>
                        {/* <input type='hidden' value={userid.id} name='userid'></input> */}
                        <Button type='submit' variant='primary'><FaUpload size={20} /> </Button>
                        </Form.Group>
					<Form.Control type="hidden" className="selected-pdf-file" value="" />
						
                    
                </InputGroup >
            </Col>
            {/*<Col lg={2} md={12} className='d-flex justify-content-end'>
                        <Button variant='secondary'><MdDelete size={20} /> Delete PDF</Button>

            </Col>*/}
        </Row>

        <Row className='align-items-center row-mapping-insurance'>
            <Col lg={4} md={12}>
            <InputGroup className="mb-4 custom-select">
                    <Form.Label><FaHandHoldingMedical size={22} /> Select An Insurance</Form.Label>
                    <Form.Select
                    id="selectinsurance"
					className="select-insurance"
                    onChange={(e) => setSelectInsurancre(e.target.value)} 
                    aria-label="Default select example">
                    <option value="">Open this select menu</option>
                    {
                            insurancelst.map((insurance,i) => {
                                return <option key={i} value={insurance.InsurancesID}>{insurance.InsuranceName}</option>
                            })
                        }
                    </Form.Select>
                    {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Please choose a Parent Category.
                    </Form.Control.Feedback>*/}
                </InputGroup >
            </Col>
            <Col lg={4} md={12}>
            <InputGroup className="mb-4 file_upload">
                    <Form.Label><MdPictureAsPdf size={22} /> Upload PDF</Form.Label>
                    <Form.Group controlId="formFile" className="mb-1 d-flex" style={{'margin-left':'1px'}}>
                        <Form.Control type='file' name='file' className="pdf-file" onChange={(e)=> {
							e.currentTarget.closest(".row-mapping-insurance").querySelector(".selected-pdf-file").value = e.target.files[0].name;
							//console.log(e.target.files[0]);
						}} />
                        {/* <input type='hidden' value={userid.id} name='userid'></input> */}
                        <Button type='button' variant='primary'><FaUpload size={20} /> </Button>
                        </Form.Group>
					<Form.Control type="hidden" className="selected-pdf-file" value="" />
						
                    
                </InputGroup >
            </Col>
            {/*<Col lg={2} md={12} className='d-flex justify-content-end'>
                        <Button variant='secondary'><MdDelete size={20} /> Delete PDF</Button>

            </Col>*/}
        </Row>
			</>
		}
		
	  
	  {
		  newRowData.map((c,i) => c)
	  }
      
      <Row >
            <Col lg={12} className='d-flex justify-content-end'>
            <Button variant='primary' onClick={() => addInsuranceRow()}><HiDocumentAdd size={20} /> Add Insurance</Button>
            </Col>
        </Row>
      



    </div>
      <Row className='justify-content-center'>
        <Col sm={3} className='d-flex '>
        <Button id='submitbtn' variant="link" type="submit" className='me-2'><FaTelegramPlane className='foms-1 ms-1' size={23} /> Submit</Button>
        
        <Button id='cancelbtn' variant="secondary" type="button" onClick={()=>window.location.replace('../coding-guidelines')}><GiCancel className='foms-1 ms-1' size={23} /> Cancel</Button>
        </Col>
    </Row>

   
  </Form>
  
  </Stack>
        </>
    );
};

export default CategoryEditForm;

