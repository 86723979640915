const IMAGES = {
   
	ENTLogo: require('./ESP-ENT-Logo.svg').default,
	ENTLogoDark: require('./ESP-ENT-Logo-dark.svg').default,
	Avatar: require('./avatar-img.svg').default,
	signIn: require('./sign-in.svg').default,
	signInImage: require('./signin-image.svg').default,
	Loader: require('./Loader.gif').default,
}

export default IMAGES;