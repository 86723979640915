import React, { useEffect, useState } from 'react';
import Auth from '../../utility/AuthConfig';
import { Card, Col, Container, Row } from 'react-bootstrap';
import {FaLaptopCode} from 'react-icons/fa';
import {MdBusinessCenter, MdHealthAndSafety} from 'react-icons/md';
import {BsFillFileEarmarkMedicalFill} from 'react-icons/bs';
import {BiSolidDashboard} from 'react-icons/bi';
import {FaHouseChimneyUser} from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const SuperAdminDashboard = () => {

    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showItem, setShowItem] = useState(null);
    
      const menuClickHandler = (param) => {
        if (showItem === param) {
          setShowItem(null);
        } else {
          setShowItem(param);
        }
      };
    
      useEffect(() => {
        /**GET CATEGORY GROUP**/
        setLoading(true);
        Auth.get('/getcategorygroup').then((response) => {
         setGroups(response.data.groups);
            //console.log(response.data);
            setLoading(false); 
        }).catch(error => {
            if(error.code === 'ERR_BAD_REQUEST'){
             console.log('error');
             localStorage.removeItem('data');
             localStorage.removeItem("token");
           window.location.pathname = '/'
            }
      
        });
     },[]);

    return (
        <>
        { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
        <Container fluid className='px-0'>
        <Row>
        <Col sm={4}>
                <Link to='../dashboard'>
                    <Card className='p-4 pb-2 text-center ds_card' style={{'background':'#02B6C0'}}>
                        <BiSolidDashboard className='mb-2 mx-auto text-white' size={45} />
                        <Card.Body className='pb-0'>
                        <Card.Text className='text-white fw-bold fs-3 mb-0'>Dashboard</Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
            <Col sm={4}>
                <Link to='../user-management'>
                    <Card className='p-4 pb-2 text-center ds_card' style={{'background':'#02B6C0'}}>
                        <FaHouseChimneyUser className='mb-2 mx-auto text-white' size={45} />
                        <Card.Body className='pb-0'>
                        <Card.Text className='text-white fw-bold fs-3 mb-0'>User Management</Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
            {
            groups.map((group, i) => {
                return  (<Col sm={4} key={i}>
                <Link to={"../manage-coding-guidelines/" + group.Value } >
                    <Card className='p-4 pb-2 text-center ds_card' style={{'background':'#02B6C0'}}>
                        <FaLaptopCode className='mb-2 mx-auto text-white' size={45} />
                        <Card.Body className='pb-0'>
                        <Card.Text className='text-white fw-bold fs-3 mb-0'>{group.Name}</Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>)
            })
        }
            
        </Row>
        </Container>
            
           
           
            
            
     
      </>
    );
};

export default SuperAdminDashboard;