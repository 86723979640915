import React, { useEffect, useState } from 'react';
import Auth from '../utility/AuthConfig';
import { Link, NavLink } from 'react-router-dom';
import { Button, Nav } from 'react-bootstrap';
import {TbListDetails} from 'react-icons/tb';
import {BsArrowRightShort} from 'react-icons/bs';
import {AiFillGold} from 'react-icons/ai';
import {BiSolidArrowToRight, BiSolidDashboard} from 'react-icons/bi';
import {FaHouseChimneyUser} from 'react-icons/fa6';

const Sidebar = ({expand, printprofileName, showprofileImg}) => {


 
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  

  const data = localStorage.getItem('data');
  const usertype = JSON.parse(data);
  //console.log(usertype);
  
  const [showItem, setShowItem] = useState(null);

  const menuClickHandler = (param) => {
    if (showItem === param) {
      setShowItem(null);
    } else {
      setShowItem(param);
    }
  };

  useEffect(() => {
     /**GET CATEGORY GROUP**/
     setLoading(true);
     Auth.get('/getcategorygroup').then((response) => {
      setGroups(response.data.groups);
         //console.log(response.data);
         setLoading(false); 
     }).catch(error => {
      if(error.code === 'ERR_BAD_REQUEST'){
       console.log('error');
       localStorage.removeItem('data');
       localStorage.removeItem("token");
     window.location.pathname = '/'
      }

  });
  },[]);


  
    return (
        <>
    { loading ? <><div className="loader-outer"><div className="lds-roller loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></> : '' }
     
     
     
     <Nav id="sidebar" className={`sidebar border-end border-secondary flex-column ${expand ? "collapsed" : ""}`}>
    <div className="sidebar-content " id="sidebar-sticky">

    <div className="sidebar-brand text-start pt-5">
        <figure className="m-0">
        <img className="img-fluid mx-auto rounded-circle bg-white" src={showprofileImg} width={70} height={70} alt="Avatar"/>
        </figure>
        </div>
        
        <div className="text-center mb-5 lft_panel_box px-3">
        <h5 className="userName fs-5 text-white text-break">{printprofileName}</h5></div>
      <ul>
      <li>
       <NavLink className='nav-link text-white fw-bold d-flex align-items-center py-2' to="dashboard">
          <BiSolidDashboard className='me-2' size={23} /> <span className='d-inline-block'>Dashboard</span></NavLink></li>

          { (usertype.type==="1" || usertype.type===1)  ?
            <li>
            <NavLink className='nav-link text-white fw-bold d-flex align-items-center py-2' to="user-management">
            <FaHouseChimneyUser className='me-2' size={23} /> <span className='d-inline-block'>User Management</span></NavLink></li> : ""
          }


          <li>
        <Link className='nav-link text-white fw-bold d-flex align-items-center py-2 dropdown_btn' onClick={() => menuClickHandler("goldenrules")}>
        <AiFillGold className='me-2' size={25} /> <span className='d-inline-block'>Golden Rules</span>
      <Button className='dropdown_btn p-0 bg-transparent align-middle ms-3' >
        <BiSolidArrowToRight size={20}  /></Button></Link></li>

        <div key="goldenrules" className={`sidebar-dropdown list-unstyled ${showItem === "goldenrules" ? "show" : null}`}>
        <ul>
                <li>
      <NavLink className='nav-link text-white fw-bold d-flex align-items-center py-2' to="insurance-lists">
        <BsArrowRightShort className='me-2' size={20} /> <span className='d-inline-block'>Insurance List</span></NavLink>
        </li></ul>
        </div>

        
        <li>
      <Link className='nav-link text-white fw-bold d-flex align-items-center py-2 dropdown_btn' onClick={() => menuClickHandler("goldenrulesDetails")}>
        <TbListDetails className='me-2' size={25} /> <span className='d-inline-block'>Golden Rules Details</span>
      <Button className=' p-0 bg-transparent align-middle ms-3' >
        <BiSolidArrowToRight size={20}  /></Button></Link></li>

        <li>
      <div key="goldenrulesDetails" className={`sidebar-dropdown list-unstyled ${showItem === "goldenrulesDetails" ? "show" : null}`}>

      <NavLink className='nav-link text-white fw-bold d-flex align-items-center py-2' to="coding-guidelines">
        <BsArrowRightShort className='me-2' size={20} /> <span className='d-inline-block'>List Of Categories</span></NavLink>

        {
            groups.map((group,i) => {
                return <ul>
                <li key={i}>
                <NavLink key={i} className='nav-link text-white fw-bold d-flex align-items-center py-2' to={"manage-coding-guidelines/" + group.Value } onClick="window.location.reload();">
        <BsArrowRightShort className='me-2' size={20} /> <span className='d-inline-block'>{group.Name}</span></NavLink></li>
        </ul>
            })
        }


        
      
        </div> </li>
        </ul>

    </div>
    </Nav>
       
        </>
    );
};

export default Sidebar;