import react from 'react';
import CategoryEditForm from '../../components/CategoryEditForm';

const CategoryEdit = () => {

    return(
        <>
        <div>
            <div className="bg-white shadow-lg py-4 px-4 rounded-4">
            <div className="d-flex align-items-center mb-3">
                    <div className="col-auto d-block">
                        <h2 className="table-title text-dark fw-semibold fs-3">Edit Category</h2>
                    </div>
                    </div>        
                  <CategoryEditForm />
                
            </div>
            
        </div>
        </>
    )
}

export default CategoryEdit;