import React from 'react';
import { Container,Row,Col,Image,Stack } from 'react-bootstrap';
import LoginComponent from '../../components/LoginComponent';
import IMAGES from '../../assets/images/IMAGES';

const Login = () => {
    return (
        <>
            <section className='sign-in'>
                <Container fluid className='px-0'>
                    
                        
                       
                        <Row className='align-items-center mx-0'>
                            <Col lg={8} md={6} className='d-flex align-items-center justify-content-center flex-wrap vh-100 pt-5'>
                            <Stack direction="vertical">
                                <figure className='mx-auto'><Image src={IMAGES.ENTLogoDark} alt="ENTSP Logo" width={150} /></figure>
                                <h2 className='text-center'>Welcome Back</h2>
                                <div className="signin-image">
                                <figure><Image className='mx-auto text-center' src={IMAGES.signInImage} alt="ENTSP Logo" width={560} /></figure>
                                </div>
                                <copyright className='small text-center'>2023 Copyright Protected. All Rights Reserved.</copyright>
                            </Stack>    
                            </Col>
                            <Col lg={4} md={6}  className='vh-100 d-flex align-items-center' style={{'background':'#00558E'}}>
                                <div className="signin-form">
                                <h2 className="form-title">Sign In</h2>
                                <p className='form-subtext'>Stay In To Your Account</p>
                                <LoginComponent />
                                
                                </div>
                            </Col>
                    </Row>
                        
                        
                        
                </Container>
            </section>
            
        </>
    );
};

export default Login;