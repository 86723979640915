import React, { useEffect, useState } from 'react';
import IMAGES from '../assets/images/IMAGES';
import { Container,Row,Col,Image,Stack, Button } from 'react-bootstrap';
import {BsFillTelephoneFill} from 'react-icons/bs';
import {HiOutlineLogout} from 'react-icons/hi';
import {GiHamburgerMenu} from 'react-icons/gi';
import { Link } from 'react-router-dom';

const Header = ({onexpandSidebar, printprofileName, showprofileImg, onLogOut}) => {

    const [issider, setIsSidebar] = useState("")


useEffect(() => {
  

    const showSidebar = localStorage.getItem('data');
    setIsSidebar(JSON.parse(showSidebar));
    

},[]);

    return (
        <>
            <header className='py-2' style={{background:'#00558E'}}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4} >
                            <Stack direction="horizontal" gap={4}>
                        {
                            (issider.type==="1" || issider.type===1)  ? <button className="sidebar-toggle" onClick={onexpandSidebar} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Navigation">
                            <GiHamburgerMenu size={30} /> </button> : ''
                        }

                        {
                            (issider.type==="2" || issider.type===2) ? <button className="sidebar-toggle" onClick={onexpandSidebar} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Navigation">
                            <GiHamburgerMenu size={30} /> </button> : null
                        } 
                            <Link to="dashboard"><Image src={IMAGES.ENTLogo} alt="ENTSP Logo" /></Link>
                            </Stack>    
                        </Col>
                        <Col md={8}>
                            <Stack direction="horizontal" gap={4}>
                            <a className="pe-2 d-flex text-white align-items-center ms-auto" href="tel:555-589-5555"><BsFillTelephoneFill className='me-3' /> 
                            <h6 className='text-white mb-0'><span className='d-block mb-1'>Call Us Today!</span>555-589-5555</h6></a>
                            <div className="d-flex text-white align-items-center">
                                <Image className="me-2 rounded-circle bg-white" src={showprofileImg} width={40} height={40} alt="Avatar" />
                                <h6 className='text-white mb-0'>Welcome, {printprofileName}</h6>
                                <Button onClick ={onLogOut} className="p-0 bg-transparent border-0 ms-2"><HiOutlineLogout size={25} /></Button>
                                
                            </div>
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    );
};

export default Header;
