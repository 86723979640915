import axios from 'axios';

const Auth = () => {
  //const serverApiEndpoint = 'http://localhost/entsp-goldenrule/public/api';
const serverApiEndpoint = 'http://107.180.103.25:420/entsp-goldenrule/public/api';
  const defaultOptions = {
    baseURL: serverApiEndpoint
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('token'));
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};

export default Auth();
