import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';

const UserProtectedRoute = () => {
    const [signedin, setSignedIn] = useState(false);
    const [usertype, setUserType] = useState("");

    const navigate = useNavigate();

        const checkToken = () => {
            const userToken = localStorage.getItem('data');
           
           

            if(!userToken || userToken === 'undefined'){
                setSignedIn(false);
                return navigate('/');
            }

            setSignedIn(true);
            setUserType(JSON.parse(userToken));
        }

        useEffect(() => {
            checkToken();
        }, [signedin]);
        
    
    return (
        <>

        {
            (signedin && (usertype.type==="3" || usertype.type===3)) ? <Dashboard /> : <h1 className="text-dark text-center m-5 py-5">Unauthorize to access this page</h1>
        }
            
        </>
    );
};

export default UserProtectedRoute;